import React, { useState } from 'react'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

interface Categories {
	selectedCategory: any
	val: any
	tags: any
	getDisabledState: any
}
export const NewCategorieRecursive: React.FC<Categories> = ({ selectedCategory, val, getDisabledState }) => {
	const [selectedTag, setSelectedTag] = useState<string>('')

	const handleChangeTag = (event: any) => {
		setSelectedTag(event.target.value as string)
		val.key = selectedCategory.param
		val.param = event.target.value
		getDisabledState()
	}

	return (
		<div className="text-white flex items-center  tag-children">
			{selectedCategory && (
				<div className="ml-4">
					<Box sx={{ minWidth: 120 }}>
						<FormControl variant="standard" fullWidth className="text-white border-white">
							<InputLabel id="tag-select-label" sx={{ color: '#000' }}>
								Genre
							</InputLabel>
							<Select value={selectedTag} onChange={handleChangeTag} sx={{ color: '#000' }}>
								{selectedCategory.value.map((tag: any) => (
									<MenuItem key={tag} value={tag} style={{ color: 'black' }}>
										{tag}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</div>
			)}
		</div>
	)
}
