import React from 'react'
import { IBreadCrumbProps } from './interfaces'
import { Container, WrapperRoute, Route, Divider } from './styles'
const Breadcrumb: React.FC<IBreadCrumbProps> = ({ routes }) => {
	return (
		<Container>
			{routes.map((item, index) => (
				<WrapperRoute key={index}>
					<Route lastRoute={index === routes.length - 1}>
						{item.link ? <a href={item.link}>{item.label}</a> : item.label}
					</Route>
					<Divider>{index < routes.length - 1 ? '/' : ''}</Divider>
				</WrapperRoute>
			))}
		</Container>
	)
}

export default Breadcrumb
