import React from 'react'
import './styles.scss'
interface Props {
	onChange: (valeu: any) => void
	value: string | number
	placeholder: string
	label?: string
	type: string
}

export const DscInput: React.FC<Props> = ({ onChange, value, placeholder, label, type }) => {
	return (
		<div className="flex flex-col w-full dsc_input">
			<div className="flex items-center gap-2">
				{label && (
					<>
						<label htmlFor={label}>{label}</label>
						<span className="required ">*</span>
					</>
				)}
			</div>
			<input
				type={type}
				placeholder={placeholder}
				name={label}
				value={value}
				onChange={e => {
					onChange(e.target.value)
				}}
			/>
		</div>
	)
}

export default DscInput
