import React, { useContext, useState } from 'react'
import KineContext from '../../../../context/KineContext'
import { FestivalService } from '../../../../service/festival/festivalService'

import '../../style.scss'
import { useNavigate } from 'react-router-dom'
import Modal from 'src/components/_Dsc/Modal'
import Button from 'src/components/_Dsc/Button'

interface Props {
	open: boolean
	setOpen: (value: boolean) => void
	description: string
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	height: 330,
	bgcolor: 'background.paper',
	border: 'none',
	boxShadow: 24,
	p: 4,
}

const ActiveInstaConstelation: React.FC<Props> = ({ open, setOpen, description }) => {
	const { user, apiState, setGlobalToast, color, setGlobalSnack } = useContext(KineContext)
	const [endTime, setEndTime] = useState<string>('m')
	const [totalNumber, setTotalNumber] = useState<number>(0)

	const [errorMessage, setErrorMessage] = useState<String>('')
	const [isError, setIsError] = useState<boolean>(false)
	const navigate = useNavigate()

	const changeEndTime = (event: any) => {
		//console.log('e', event.target.value)
		setEndTime(event?.target.value)
	}

	const onCancel = () => {
		setOpen(false)
	}

	const changeEndBlock = (e: any) => {
		const inputAsString = String(e).trim()
		if (inputAsString.charAt(0) === '0' && inputAsString.length > 1) {
			setErrorMessage('The value must be above 30')
			setTotalNumber(0)
			setIsError(true)
			return false
		}
		if (Number(e) < 30) {
			setErrorMessage('The value must be above 30')
			setTotalNumber(e)
			setIsError(true)
			return false
		}

		if (Number(e) >= 30) {
			setErrorMessage('')
			setIsError(false)
			setTotalNumber(e)
		}
		console.log('isError', isError)
	}

	const handleConfirm = async () => {
		setGlobalToast(true)
		let id = await FestivalService.getLastedCreatedFestival(user, apiState)

		if (id) {
			await FestivalService.activeAsapFestival(
				user,
				apiState,
				id,
				totalNumber,
				endTime,
				setGlobalToast,
				description,
				setGlobalSnack,
			)
			navigate('/details/' + id)
			handleClose()
		} else {
			setGlobalToast(false)
			alert(' An error occurred while processing the creation, Please activate the festival later')
		}
	}

	const handleClose = async (param?: boolean) => {
		setOpen(false)
		setGlobalToast(false)
		if (param) {
			let id = await FestivalService.getLastedCreatedFestival(user, apiState)
			if (id) {
				navigate('/details/' + id)
			} else {
				navigate('/my-constellations')
			}
		}
	}

	return (
		<Modal
			open={open}
			title={'Publish Constellation'}
			onClose={onCancel}
			type="primary"
			size="large"
			cancelLabel="Active later"
			confirmLabel="Active"
			onCancel={() => {
				handleClose(true)
			}}
			onConfirm={handleConfirm}
			confirmDisabled={(isError && endTime !== 'd' && endTime !== 'h') || totalNumber < 1}
		>
			<div className="w-full h-full flex flex-col justify-between">
				<div>
					<p className="text-[#735a5a] mt-5 text-sm">
						After publishing your Constellation you can’t make any changes. If you choose to ”activate later” you can go
						back to your selection and add or remove entries. The constellation will be listed as “pending” under
						constellations on your profile page.
					</p>
					<div className="w-full  mt-4 flex items-center">
						<div className="flex items-normal ">
							<div className="flex flex-col">
								<input
									type="number"
									placeholder="ex:3"
									className=" p-3 rounded-lg mr-2 border"
									onChange={event => {
										changeEndBlock(event.target.value)
									}}
								/>
								<div className="flex items-center gap-2 mt-2">
									{errorMessage && endTime !== 'd' && endTime !== 'h' && <p className="text-red-400">{errorMessage}</p>}
									{!errorMessage && endTime !== 'd' && endTime !== 'h' && <p>Min time: 30 Minutes</p>}
								</div>
							</div>
							<div>
								<select
									className="p-3 rounded border"
									onChange={event => {
										changeEndTime(event)
									}}
									style={{ minHeight: '51px' }}
								>
									<option value={'m'}>Minutes</option>
									<option value={'h'}>Hour(s)</option>
									<option value={'d'}>Day(s)</option>
								</select>
								<span className="opacity-0">Min time: 30 Minutes</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ActiveInstaConstelation

/**	<div className="modal-body">
    {
      /**
       * <Modal
      open={open}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          invisible: true,
        },
      }}>
      <Fade in={open}>
        <Box sx={style}>
          <div className="w-full h-full flex flex-col justify-between">
            <div>
              <div className="flex items-center gap-5">
                <span className="material-symbols-outlined text-[54px] text-yellow-500">send_time_extension</span>
                <p className="text-2xl font-bold">Publish Constellation</p>
              </div>
              <p className="text-[#735a5a] mt-5 text-sm">
                After publishing your Constellation you can’t make any changes. If you choose to ”activate later” you
                can go back to your selection and add or remove entries. The constellation will be listed as “pending”
                under constellations on your profile page.
              </p>
              <div className="w-full  mt-4 flex items-center">
                <div className="flex items-normal ">
                  <div className="flex flex-col">
                    <input
                      type="number"
                      placeholder="ex:3"
                      className=" p-3 rounded-lg mr-2 border"
                      onChange={event => {
                        changeEndBlock(event.target.value)
                      }}
                    />
                    <div className="flex items-center gap-2 mt-2">
                      {errorMessage && endTime !== 'd' && endTime !== 'h' && (
                        <p className="text-red-400">{errorMessage}</p>
                      )}
                      {!errorMessage && endTime !== 'd' && endTime !== 'h' && <p>Min time: 30 Minutes</p>}
                    </div>
                  </div>
                  <div>
                    <select
                      className="p-3 rounded border"
                      onChange={event => {
                        changeEndTime(event)
                      }}
                      style={{ minHeight: '51px' }}>
                      <option value={'m'}>Minutes</option>
                      <option value={'h'}>Hour(s)</option>
                      <option value={'d'}>Day(s)</option>
                    </select>
                    <span className="opacity-0">Min time: 30 Minutes</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full items-center flex justify-end gap-5 modal-btn">
              <button
                className="font-bold"
                onClick={() => {
                  handleClose(true)
                }}>
                Activate later
              </button>
              {endTime === 'm' && totalNumber >= 30 && (
                <button
                  onClick={() => {
                    handleConfirm()
                  }}
                  className={`p-1 rounded-[4px] text-white bg-[${color.action}]`}>
                  Active now
                </button>
              )}
              {(endTime === 'd' || endTime === 'h') && (
                <div>
                  <ActionButton onSubmit={handleConfirm} text="Active now" size="sm" />
                </div>
              )}
              {totalNumber < 30 && endTime !== 'd' && endTime !== 'h' && (
                <div>
                  <ActionButton disabled={true} onSubmit={() => {}} text="Active now" size="sm" />
                </div>
              )}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
       */
