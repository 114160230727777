import Swal from 'sweetalert2'
import API from '../api'

const sendNotify = async (user: string, content: string, type: number, status: number) => {
	const payload = {
		user: user,
		content: content,
		type: type,
		status: status,
	}
	try {
		let req = await API.post(`/api/notification/`, payload)

		return req.data
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
		})
	}
}

const updateNotify = async (user: string, content: string, type: number, status: number, id: number) => {
	let payload = {
		user: user,
		content: content,
		type: type,
		status: status,
		id: id,
	}

	try {
		let req = await API.post(`/api/notification/`, payload)

		return req.data
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
		})
	}
}

const getAllNotify = async (user: number) => {
	const payload = {
		user: user,
	}
	try {
		let req = await API.get(`/api/notification/`, { params: payload })

		return req.data
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
		})
	}
}

const removeAll = async (user: string) => {
	const payload = {
		user: user,
	}
	try {
		let req = await API.post(`/api/notification/remove`, payload)

		return req.data
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
		})
	}
}

export const NotificationService = {
	sendNotify,
	getAllNotify,
	updateNotify,
	removeAll,
}
