import React, { useState } from 'react'
import MyFestivals from '../../../socialView/Festivals'
import KineContext from '../../../../context/KineContext'
import '../../style.scss'
import { useNavigate } from 'react-router-dom'
import { FestivalService } from 'src/service/festival/festivalService'
import { Loading } from 'src/components/loading'
import DscTab from 'src/components/_Dsc/tab'

interface Categorie {
	label: string
	value: string
}

const UserFestivals: React.FC = () => {
	const { user, apiState } = React.useContext(KineContext)

	const [fetch, setFetch] = useState<boolean>(true)
	const [currentTab, setCurrentTab] = useState('activeFestivals')
	const navigate = useNavigate()
	const [isSelected, setIsSelected] = useState('Active')
	const categories = [
		{
			label: 'Active',
			value: 'activeFestivals',
		},
		{
			label: 'Pending Activation',
			value: 'awaitingActivationFestivals',
		},
		{
			label: 'Closed',
			value: 'finishedFestivals',
		},
	]
	const changeTab = (tab: Categorie) => {
		setCurrentTab(tab.value)
		setIsSelected(tab.label)
	}

	const getData = async () => {
		setFetch(true)
		await FestivalService.getUserFestivalWalletData(user, apiState)

		setFetch(false)
	}

	React.useEffect(() => {
		if (!user || !user.address) navigate('/')
		if (apiState) {
			getData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	return (
		<div className="my-profile-component">
			{user && user.address && (
				<>
					{!fetch ? (
						<div>
							<div className="w-full flex items-center mt-4">
								{categories.map((val: Categorie, i: number) => (
									<div key={i}>
										<DscTab isSelected={val.label === isSelected}>
											<button onClick={() => changeTab(val)}>{val.label}</button>
										</DscTab>
									</div>
								))}
							</div>
							<div className="border-2 flex rounded-[4px] items-center p-2 mt-2">
								{currentTab === 'awaitingActivationFestivals' && (
									<MyFestivals user={user.address} keyParam={currentTab} />
								)}
								{currentTab === 'activeFestivals' && <MyFestivals user={user.address} keyParam={currentTab} />}
								{currentTab === 'finishedFestivals' && <MyFestivals user={user.address} keyParam={currentTab} />}
							</div>
						</div>
					) : (
						<Loading />
					)}
				</>
			)}
		</div>
	)
}

export default UserFestivals
