import React from 'react'
import { FestivalService } from '../../service/festival/festivalService'
import KineContext from '../../context/KineContext'
import { Skeleton } from '@mui/material'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { MovieWinnerCard } from './components/movieCard'
import WinnersModal from '../details/components/WinnersModal'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import BreadCrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { TextStyleH1, TextStyleH2 } from 'src/components/_Dsc/typography'
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
}

const options = ['Crypto', 'Comedy', 'Artificial Intelligence', 'Others']
const path = [
	{ label: 'Home', link: '/' },
	{ label: 'See', link: '' },
]
const defaultVal = [1, 2, 3, 4]
const WinnersList: React.FC = () => {
	const { apiState } = React.useContext(KineContext)
	const [fetch, setFetch] = React.useState(true)
	const [data, setdata] = React.useState([])
	const [temp, setTemp] = React.useState([])
	const [dataSelect, setDataSelect] = React.useState([])
	const [winnersModal, setWinnersModal] = React.useState(false)
	const [tab, setTab] = React.useState('Crypto')

	const getData = async () => {
		setFetch(true)
		let req = await FestivalService.getFestivalsWinner(false, apiState)

		setTemp(req)
		onChangeTab('Crypto', req)
		setFetch(false)
	}

	const changeCurrentData = (data: any) => {
		setWinnersModal(true)
		setDataSelect(data)
	}

	const filterValue = (tab: any, _req: any) => {
		let formated: any = []
		if (tab !== 'Others') {
			//	setdata(temp)
			let selectedReq = _req ? _req : temp
			selectedReq.forEach((val: any) => {
				if (val.categoriesAndTags) {
					val.categoriesAndTags.forEach((category: any) => {
						if (category && category.includes(tab)) {
							formated.push(val)
						}
					})
				}
			})
			//setdata(formated)
		} else {
			let selectedReq = _req ? _req : temp
			selectedReq.forEach((val: any) => {
				if (val.categoriesAndTags) {
					val.categoriesAndTags.forEach((category: any) => {
						if (category && !category.includes(tab)) {
							formated.push(val)
						}
					})
				}
			})
		}

		setdata(formated)
		return formated
	}

	const onChangeTab = (tab: string, _req: any) => {
		setTab(tab)
		if (!_req) {
			filterValue(tab, false)
		} else {
			filterValue(tab, _req)
		}
	}

	React.useEffect(() => {
		if (apiState) {
			getData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])

	return (
		<div className="flex flex-col w-full page-padding ">
			<BreadCrumb routes={path} />
			{winnersModal && <WinnersModal setOpen={setWinnersModal} open={winnersModal} data={dataSelect} />}
			<CardInner>
				<CardContainer>
					<div className="flex items-center justify-between w-full ">
						<TextStyleH1>See</TextStyleH1>
						<Button value="Create channel" disabled size="small"></Button>
					</div>
					<div className="mt-4 gap-2 flex flex-col">
						{!fetch && data ? (
							<div className="flex flex-col w-full h-full bg-white p-4 rounded-[4px] ">
								<div className="flex items-center gap-8">
									{options.map((val: string, i: number) => (
										<Button
											key={i}
											onClick={() => {
												onChangeTab(val, false)
											}}
											value={val}
											type={val === tab ? 'outlined' : 'primary'}
											size="small"></Button>
									))}
								</div>
							</div>
						) : (
							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
								{defaultVal?.map(
									(value: any, i: number) =>
										i < 6 && (
											<div key={i}>
												<Skeleton variant="rectangular" width={'100%'} height={200} />
											</div>
										),
								)}
							</div>
						)}
						{!fetch && data && data.length ? (
							<Carousel responsive={responsive}>
								{data &&
									data.length &&
									data.map((val, i: number) => (
										<div key={i} className="w-full bg-white">
											<MovieWinnerCard data={val} changeCurrentData={changeCurrentData} />
										</div>
									))}
							</Carousel>
						) : (
							''
						)}
					</div>
				</CardContainer>
			</CardInner>

			{!fetch && (!data || !data.length) ? (
				<CardInner className="bg-white">
					<CardContainer className="flex items-center justify-center">
						<TextStyleH2>No data to display</TextStyleH2>
					</CardContainer>
				</CardInner>
			) : (
				''
			)}
		</div>
	)
}

export default WinnersList
