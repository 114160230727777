import { css } from 'styled-components'

export const cssScrollbar = css`
	scrollbar-width: initial;

	&::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--icon-neutral);
		border-radius: 3.5px;
	}

	&::-webkit-scrollbar-track-piece {
		margin: 0px;
	}
`

export const cssUnclickable = css`
	pointer-events: none;
`

export const cssUnselectable = css`
	&::-moz-user-select: -moz-none;
	&::-khtml-user-select: none;
	&::-webkit-user-select: none;
	&::-o-user-select: none;
	user-select: none;
`

export const cssNone = css`
	visibility: hidden;
	opacity: 0;
	transition: var(--transition-fast);
`

export const cssBlock = css`
	visibility: visible;
	opacity: 1;
	transition: var(--transition-fast);
`

export const cssSpinningLoader = css`
	font-size: 14px;
	color: var(--icon-default);
	left: auto !important;
	pointer-events: all;
	cursor: pointer;
	animation: dsc-input-text-loading 1s infinite linear;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cmask id='mask0_1945_1505' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'%3E%3Crect width='16' height='16' fill='%2344546F'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1945_1505)'%3E%3Cmask id='mask1_1945_1505' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='-1' y='0' width='17' height='16'%3E%3Ccircle cx='7.9837' cy='8' r='8' fill='%2344546F'/%3E%3C/mask%3E%3Cg mask='url(%23mask1_1945_1505)'%3E%3Cpath d='M6.69597 0.0815549C6.80648 0.0590932 6.91529 0.0344461 7.0263 0.0144136C7.48693 -0.0640311 7.87403 0.19122 7.9721 0.636768C8.06769 1.07017 7.83836 1.45577 7.38344 1.56847C6.60787 1.759 5.83961 1.96701 5.15475 2.3946C3.03473 3.71852 1.83064 5.61014 1.70216 8.15971C1.69189 8.35532 1.69171 8.55142 1.68649 8.74727C1.6711 9.25003 1.35944 9.57521 0.891421 9.5742C0.458517 9.57365 0.135698 9.24082 0.110281 8.75268C0.0569797 7.76425 0.181971 6.79145 0.494291 5.86025C1.52004 2.80197 3.61538 0.90122 6.69597 0.0815549Z' fill='%23233043'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	height: 16px;
	width: 16px;
	display: block;
`
