import styled from 'styled-components'

import { cssTextStyleH2 } from '../typography'

interface DsyContent {
	size: 'small' | 'medium' | 'large' | 'xl'
}

export const DsyContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: var(--z-index-modal-backdrop);
`

export const DsyShadow = styled.div`
	background-color: var(--background-shadow);
	opacity: 0.4;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`

export const DsyContent = styled.div<DsyContent>`
	border-radius: 4px;
	background: var(--surface-default, #fff);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: var(--z-index-modal);
	width: ${({ size }) => (size === 'small' ? '400px' : size === 'medium' ? '600px' : size === 'large' ?'800px' : '90%')};
	height: ${({size}) => (size === 'xl' ? '80%' : '')}
`

export const DsyHeaderContainer = styled.div`
	padding: 24px 24px 16px;
	display: grid;
	grid-template-columns: auto 32px;
	gap: 24px;
	align-items: center;
`

export const DsyHeaderTitle = styled.div`
	${cssTextStyleH2}
	display: flex;
	gap: 12px;
	align-items: center;
`

export const DsyBody = styled.div`
	padding: 0px 24px;
	max-height: 70vh;
	height: inherit;
	overflow-y: visible;
`

export const DsyFooter = styled.div`
	padding: 16px 24px 24px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;
`
