import React from 'react'
import { useNavigate } from 'react-router-dom'
import './style.scss'
import Tooltip from '@mui/material/Tooltip'
import { Festival } from '../../interface'

import KineContext from '../../context/KineContext'
import TextH3 from '../_Dsc/typography/TextH3'
import BlockCounter from '../../pages/details/components/Counter'
import { FestivalService } from 'src/service/festival/festivalService'
import { planksToTokens } from 'src/service/substrate/formated'

const Card: React.FC<{
	data: Festival
	movie?: boolean
	action?: any
	small?: any
	currentBlockNumber?: string
	removeLock?: boolean
}> = ({ data, currentBlockNumber, removeLock }) => {
	const { color, apiState } = React.useContext(KineContext)
	const navigate = useNavigate()
	const redirect = (id: any) => {
		navigate('/details/' + id)
	}
	const [currentBlockNumberState, setCurrentBlockNumberState] = React.useState('')
	const [fetch, setFetch] = React.useState(true)
	const isHex = (str: string): boolean => {
		const hexWithoutPrefix = str.startsWith('0x') ? str.slice(2) : str
		return /^[0-9A-Fa-f]+$/i.test(hexWithoutPrefix)
	}

	const currentBlock = async () => {
		setFetch(true)
		let req = await FestivalService.getCurrentAccBlock(apiState)
		setCurrentBlockNumberState(req)
		setFetch(false)
	}

	const decodeHex = (hex: string): string => {
		if (isHex(hex)) {
			const hexWithoutPrefix = hex.startsWith('0x') ? hex.slice(2) : hex

			let decodedString = ''
			for (let i = 0; i < hexWithoutPrefix.length; i += 2) {
				const hexPair = hexWithoutPrefix.substr(i, 2)
				const decimalValue = parseInt(hexPair, 16)
				decodedString += String.fromCharCode(decimalValue)
			}

			decodedString = decodeURIComponent(escape(decodedString))

			return decodedString
		} else {
			return hex
		}
	}
	const styleCounter = {
		fontSize: '16px',
	}

	React.useEffect(() => {
		currentBlock()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div
			className={` rounded-[4px] card-movie-bg  flex flex-col justify-between  cursor-pointer cards-hover grow flex `}
			onClick={() => {
				redirect(data.id)
			}}
		>
			<div className="ml-2">
				<div className={` flex flex-col  w-full  text-[${color.text}]`}>
					<h3 className="truncate w-full flex items-center  font-bold ">{decodeHex(data.name)}</h3>
				</div>
				<div className="flex flex-col gap-1 justify-between  ">
					<TextH3>{decodeHex(data.description)}</TextH3>
					<TextH3>
						Status: {data.status === 'AwaitingActivation' ? 'Pending' : data.status === 'Active' ? 'Active' : 'End'}
					</TextH3>
					{!fetch && !removeLock && (
						<div className="-ml-[2px]">
							<BlockCounter
								data={data.blockStartEnd}
								sx={styleCounter}
								currentBlockNumber={currentBlockNumber ? currentBlockNumber : currentBlockNumberState}
							/>
						</div>
					)}

					{data.categoriesAndTags && (
						<div className="flex items-center gap-2  tags justify-between mr-4">
							{data?.categoriesAndTags?.map((value: string, i: number) => (
								<div className="font-bold" key={i}>
									#{value[1]}
								</div>
							))}
							{data && data.totalLockup && (
								<Tooltip title="Kinex Token" placement="right-start">
									<div className="font-bold flex items-center gap-1">
										<p className="text-xl ">{planksToTokens(parseInt(data.totalLockup))}</p>
										Kinex
									</div>
								</Tooltip>
							)}
						</div>
					)}
					{!data.categoriesAndTags ||
						(!data.categoriesAndTags.length && (
							<div className="flex items-center gap-2 ml-2 tags invisible">
								<div className="tag">''</div>
							</div>
						))}
				</div>
			</div>
		</div>
	)
}

export default Card
