import React from 'react'
import Vote from '../../../../components/vote/Vote'
import ReportModal from '../../../../components/reportModal'
import KineContext from '../../../../context/KineContext'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'

interface Props {
	selectedMovie: any
	user: any
	sendVote: any
	videoSrc: any
	isFetch: boolean
	loaderCurrentMovieVotes: (number: string) => number
	totalLockMovie: (number: string) => number
	currentUserTotalLock: (number: string) => number
}

const CurrentMovie: React.FC<Props> = ({
	selectedMovie,
	user,
	videoSrc,
	sendVote,
	isFetch,
	loaderCurrentMovieVotes,
	totalLockMovie,
	currentUserTotalLock,
}) => {
	const { color } = React.useContext(KineContext)
	const [showReport, setShowReport] = React.useState<boolean>(false)
	const onChangeUpdateReport = () => {
		setShowReport(!showReport)
	}

	return (
		<CardInner>
			{selectedMovie && selectedMovie.includes('youtube') ? (
				<div className={`gap-4 justify-center w-full bg-[#efefef]  p-2 flex items-center`}>
					<div className="flex items-center gap-4">
						<p>
							Your total lock <b>{currentUserTotalLock(selectedMovie) || 0}</b>
						</p>
						<TextStyleBodyDefaultMedium>
							Total KinexPlay lock <b className="underline">{totalLockMovie(selectedMovie) || 0}</b>
						</TextStyleBodyDefaultMedium>
					</div>

					{user && user.address && <Vote isDisabled={!user} action={sendVote} data={selectedMovie} isFetch={isFetch} />}
				</div>
			) : selectedMovie.includes('tiktok.com') ? (
				<div
					className="flex items-center gap-2 justify-center m-auto"
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					<p style={{ color: color.text }}>{videoSrc.title}</p>
					<p style={{ color: color.text }}>
						Owner: <b>{videoSrc?.author}</b>
					</p>
					<p className="votes-style">Votes: {loaderCurrentMovieVotes(selectedMovie) || 0}</p>

					{user && user.address && (
						<div className="mt-10">
							<Vote isDisabled={!user} action={sendVote} data={selectedMovie} isFetch={isFetch} />
						</div>
					)}
				</div>
			) : (
				''
			)}
			<ReportModal opened={showReport} setOpen={onChangeUpdateReport} isRanking={true} currentEnum={3} />
		</CardInner>
	)
}

export default CurrentMovie
