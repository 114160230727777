import React from 'react'

interface Props {
	message: string
}
export const SuccessToast: React.FC<Props> = ({ message }) => {
	return (
		<div className="w-full flex items-center relative justify-center">
			<div
				id="toast-success"
				className="flex fixed justify-center z-[100] top-10 w-full items-center w-full p-2  text-gray-500 bg-white rounded-lg shadow max-w-xs "
				role="alert">
				<div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-700 bg-green-700 rounded-full">
					<svg
						className="w-5 h-5"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 20 20">
						<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
					</svg>
					<span className="sr-only">Check icon</span>
				</div>
				<div className="ms-3 text-sm font-normal">{message}</div>
				<button
					type="button"
					className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
					data-dismiss-target="#toast-success"
					aria-label="Close">
					<span className="sr-only">Close</span>
					<svg
						className="w-3 h-3"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 14 14">
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
						
							d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
						/>
					</svg>
				</button>
			</div>
		</div>
	)
}
