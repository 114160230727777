import React from 'react'
import MovieCard from '../../components/movieCard/MovieCard'
import AddMovie from '../../components/addMovieModal'
import { TikTokEmbed } from 'react-social-media-embed'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Vote from '../../components/vote/Vote'

import { Festival, UserProfile } from '../../interface'
import { FestivalService } from '../../service/festival/festivalService'
import KineContext from '../../context/KineContext'

import { globals } from '../../_helpers'
import './style.scss'

import SkeletonDetail from './Skelleton'

import ReportModal from '../../components/reportModal'
import ActiveFestival from './components/ActiveFestival'

import { Link } from 'react-router-dom'
import UserVotesModal from './components/votesModal'
import { getSocialProfileByAddres } from '../../service/social/socialService'
import TextH3 from '../../components/_Dsc/typography/TextH3'
import BlockCounter from './components/Counter'
import WinnersModal from './components/WinnersModal'
import BlockCounterEnd from './components/EndedTime'
import { planksToTokens, tokensToPlanks } from 'src/service/substrate/formated'
import DscSnackBar from 'src/components/_Dsc/snackbar'
import EditConstellationData from './components/EditTitleAndDescription'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'

import { TextStyleBodyMedium, TextStyleH1 } from 'src/components/_Dsc/typography'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { ApiPromise, Keyring, WsProvider } from '@polkadot/api'

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
}

export interface Data {
	title: string
	owner: string
	ccValue: string
}

function Details() {
	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'Constellations', link: '/constellations' },
		{ label: window.location.pathname.split('/')[2], link: '' },
	]
	const { user, color, apiState, profile } = React.useContext(KineContext)
	const [data, setData] = React.useState<any>([])
	const [fetch, setFetch] = React.useState<boolean>(false)
	const [movies, setMovies] = React.useState<any>([])
	const [existMovie, setExistMovie] = React.useState<boolean>(false)
	const [openAddMovie, setOpenAddMovie] = React.useState<boolean>(false)
	const [currentSelectedMovie, setCurrentSelectedMovie] = React.useState<any>(null)
	const [videoSrc, setVideoSrc] = React.useState<any>({})

	const [showReport, setShowReport] = React.useState<boolean>(false)
	const [type, setType] = React.useState<string>('external')
	const [activeFestivalState, setActiveFestivalState] = React.useState(false)

	const [fetchInser, setFetchInsert] = React.useState<any>(false)
	const [externalFetch, setExternalFetch] = React.useState<boolean>(false)
	const [editFestivalData, setEditFestivalData] = React.useState<boolean>(false)
	const [openVotesModal, setOpenVotesModal] = React.useState(false)
	const [winnersModal, setWinnersModal] = React.useState(false)
	const [users, setUsers] = React.useState([])
	const [currentBlockNumber, setCurrentBlockNumber] = React.useState('')
	const [currentKey, setCurrentKey] = React.useState('')
	const [showBar, setShowBar] = React.useState(false)
	const [typeSnackBar, setTypeSnackbar] = React.useState('')
	const [ownerDetails, setOwnerDetails] = React.useState<UserProfile>({
		cover: '',
		created_at: '',
		description: '',
		followers: 0,
		following: 0,
		id: 0,
		img: '',
		name: '',
		updated_at: '',
		user: '',
	})

	const styleCounter = {
		fontSize: '30px',
	}

	const styleCounterEnd = {
		fontSize: '14px',
	}

	const [counter, setCounter] = React.useState<string[]>([])

	const handleClose = () => setOpenAddMovie(false)

	const getFestivalDetails = async () => {
		setFetch(false)
		const id = window.location.pathname.split('/')[2]
		const request = await FestivalService.getFestivalsById(id, apiState)
		await currentBlock()
		if (request) {
			request.name = decodeHex(request.name)
			setData(request)
		}

		if (request && request.owner) {
			let req = await getSocialProfileByAddres(request.owner)
			setOwnerDetails(req)
		}
		//console.log('asa', request)

		if (request && request.blockStartEnd) {
			setCounter(request.blockStartEnd)
		}

		if (request.externalMovies && request.externalMovies.length > 0) {
			setMovies(request.externalMovies)
			setExistMovie(true)
		}

		setFetch(true)

		setFetchInsert(false)
	}

	const changeCurrentMovie = async (movie: string) => {
		if (movie.includes('youtube')) {
			setCurrentSelectedMovie(movie)

			return globals.getVideoSrc(movie, setVideoSrc, 'youtube')
		} else if (movie.includes('tiktok.com')) {
			setCurrentSelectedMovie(movie)

			return globals.getVideoSrc(movie, setVideoSrc, 'tiktok')
		} else {
			console.error('URL de vídeo não suportada:', movie)
		}
	}

	const sendVote = async (movieId: string, amount: any) => {
		const id = window.location.pathname.split('/')[2]
		if (!profile || !profile.name) return
		if (profile && data.maxEntry !== '0' && tokensToPlanks(amount) > tokensToPlanks(1)) {
			if (parseInt(amount) <= Number(data.maxEntry)) {
				const req = await FestivalService.sendVoteInMovie(
					user,
					id,
					movieId,
					amount,
					apiState,
					data.owner,
					showSnackBar,
					profile.name,
				)
				await getCurrentVotes()
				return req
			} else {
				alert(`The maximum value of tokens for voting at this festival is ${planksToTokens(data.maxEntry)}`)
			}
		} else {
			await FestivalService.sendVoteInMovie(user, id, movieId, amount, apiState, data.owner, showSnackBar, profile.name)
		}
	}

	const getCurrentVotes = async () => {
		const id = window.location.pathname.split('/')[2]
		const request = await FestivalService.getFestivalsById(id, apiState)
		setData(request)
	}

	/**
 * 
 *  	const inserMovieInFestival = async (movieId: string, data: any) => {
		setFetchInsert(movieId)
		const festivalId = window.location.pathname.split('/')[2]
		await FestivalService.insertMovie(festivalId, movieId[0], user, type)
		setFetchInsert(false)
	}

 */
	const showModalUsers = async (movieId: string) => {
		if (movieId && data && data.voteMap) {
			setCurrentKey(movieId)
			const matchingVotes = data.voteMap[movieId]
			setUsers(matchingVotes)
			setOpenVotesModal(true)
		}
	}

	const onChangeUpdateReport = () => {
		setShowReport(!showReport)
	}

	const handleActivationModal = () => {
		setActiveFestivalState(!activeFestivalState)
	}

	const handleActivationModalEdit = () => {
		setEditFestivalData(!editFestivalData)
	}

	const verifyUpdate = async () => {
		//setFetch(false)
		const id = window.location.pathname.split('/')[2]
		const request = await FestivalService.getFestivalsById(id, apiState)

		if (request) {
			request.name = decodeHex(request.name)
			request.description = decodeHex(request.description)
			setData(request)
		}

		if (request.externalMovies && request.externalMovies.length > 0) {
			setMovies(request.externalMovies)
			setExistMovie(true)
		}
		//await currentBlock()
		return true
	}
	const verifyUrlMovie = (url: string) => {
		try {
			if (currentSelectedMovie.includes(url)) {
				return true
			} else {
				return false
			}
		} catch (e) {
			console.log(e)
		}
	}

	const isHex = (str: string): boolean => {
		const hexWithoutPrefix = str.startsWith('0x') ? str.slice(2) : str
		return /^[0-9A-Fa-f]+$/i.test(hexWithoutPrefix)
	}

	const decodeHex = (hex: string): string => {
		if (isHex(hex)) {
			const hexWithoutPrefix = hex.startsWith('0x') ? hex.slice(2) : hex
			let decodedString = ''
			for (let i = 0; i < hexWithoutPrefix.length; i += 2) {
				const hexPair = hexWithoutPrefix.substr(i, 2)
				const decimalValue = parseInt(hexPair, 16)
				decodedString += String.fromCharCode(decimalValue)
			}

			// Trata corretamente caracteres especiais
			decodedString = decodeURIComponent(escape(decodedString))

			return decodedString
		} else {
			return hex
		}
	}

	const currentBlock = async () => {
		let req = await FestivalService.getCurrentAccBlock(apiState)
		//console.log('currentBlockNumber', req)
		//console.log('data', data)
		setCurrentBlockNumber(req)
	}

	const currentUserTotalLock = (movieId: any) => {
		let totalVotes = 0
		if (movieId && data.voteMap && data.voteMap[movieId]) {
			data.voteMap[movieId].forEach((val: any) => {
				totalVotes += planksToTokens(val.amount)
			})
		}
		//console.log(totalVotes)
		return totalVotes
	}

	const getTotalParticipants = (movieId: any) => {
		let totalVotes = 0
		if (movieId && data.voteMap && data.voteMap[movieId]) {
			totalVotes = data.voteMap[movieId].length
		}

		return totalVotes
	}

	const showSnackBar = async (param: boolean, type: string, close: boolean) => {
		setShowBar(param)
		setTypeSnackbar(type)
		if (close) {
			const timer = setTimeout(() => {
				setShowBar(false)
			}, 4000)
			return () => clearTimeout(timer)
		}
	}

	const calculateVotingPower = () => {
		const duracaoPeriodoDecrescimo =
			parseInt(data.blockStartEnd[1].replace(/,/g, '')) - parseInt(data.votePowerDecreaseBlock.replace(/,/g, ''))
		const blocksIntoDecrementPeriod =
			parseInt(currentBlockNumber.replace(/,/g, '')) - parseInt(data.votePowerDecreaseBlock.replace(/,/g, ''))
		const remainingProportion = (duracaoPeriodoDecrescimo - blocksIntoDecrementPeriod) / duracaoPeriodoDecrescimo

		const poderVoto = 100 * remainingProportion
		return poderVoto
	}

	React.useEffect(() => {
		setCurrentSelectedMovie(null)
		if (apiState) {
			getFestivalDetails()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, apiState])

	React.useEffect(() => {
		if (apiState) {
			const interval = setInterval(() => {
				verifyUpdate()
			}, 2000)
			return () => clearInterval(interval)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])

	return (
		<div className="page-padding pb-[90px]">
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer>
					{currentKey && (
						<UserVotesModal
							open={openVotesModal}
							setOpen={setOpenVotesModal}
							data={users}
							currentKey={currentKey}
							setCurrentKey={setCurrentKey}
						/>
					)}
					{winnersModal && <WinnersModal setOpen={setWinnersModal} open={winnersModal} data={data} />}

					{!fetch ? (
						<SkeletonDetail />
					) : (
						<div className="h-full w-full flex flex-col gap-4 mt-10">
							<div className="w-full ">
								<div className="flex flex-col items-center gap-4 p-[10px] bg-white">
									<div className="w-full flex items-center gap-2  flex-col">
										<TextStyleH1>{data?.description}</TextStyleH1>
										<TextStyleH1>{data?.name}</TextStyleH1>

										{ownerDetails && (
											<div className="mb-2 flex">
												{ownerDetails && (
													<Link
														to={`/social/view/${ownerDetails.user}`}
														className="flex items-center gap-4 hover:opacity-80 p-1">
														<span className="material-symbols-outlined ">account_circle</span>
														<TextH3>{ownerDetails.name}</TextH3>
													</Link>
												)}
											</div>
										)}
										{data &&
											currentBlockNumber &&
											data.blockStartEnd &&
											data.blockStartEnd.length &&
											data.blockStartEnd[0] &&
											data.blockStartEnd[0] !== '0' &&
											data.status === 'Active' && (
												<div className={`w-full justify-center flex items-center gap-4  header-title bg-white`}>
													<div className="flex flex-col items-center">
														<BlockCounter data={counter} sx={styleCounter} currentBlockNumber={currentBlockNumber} />
														{data && currentBlockNumber && (
															<BlockCounterEnd
																currentSelectedMovie={currentSelectedMovie}
																data={data.votePowerDecreaseBlock}
																sx={styleCounterEnd}
																currentBlockNumber={currentBlockNumber}
																calculateVotingPower={calculateVotingPower}
															/>
														)}
													</div>
												</div>
											)}

										{data.status !== 'Active' &&
											data.status !== 'FinishedNotEnoughVotes' &&
											data.status !== 'AwaitingActivation' && (
												<Button
													size="small"
													value="	View winner List"
													onClick={() => {
														setWinnersModal(true)
													}}></Button>
											)}
										{data.status !== 'Active' && data.status === 'FinishedNotEnoughVotes' && (
											<span className="cursor-pointer hover:bg-gray-200 hover:opacity-80 p-2">
												Finalized without enough votes to define a winner
											</span>
										)}
										{/**
                   * <p className={`text-[${color.text}] text-weight text-lg`}>
									Total votes: <span className={`text-[${color.text}]`}> {data?.totalLockup}</span> Kinex
								</p>
                   */}
									</div>
								</div>
								<div className="flex items-center justify-end mt-[8px]">
									{user && user.address && data.status === 'AwaitingActivation' && user.address === data.owner && (
										<div className="flex  items-end gap-2">
											<Button
												value="New Entry"
												size="small"
												onClick={() => {
													setOpenAddMovie(true)
												}}></Button>
											{data && data.status === 'AwaitingActivation' && (
												<div className="flex items-end gap-2 ">
													<Button
														value="	Question & Description"
														size="small"
														onClick={() => {
															setEditFestivalData(!editFestivalData)
														}}></Button>
													<Button
														size="small"
														value="Publish"
														onClick={() => {
															setActiveFestivalState(!activeFestivalState)
														}}></Button>
												</div>
											)}
										</div>
									)}
								</div>
								<div className="flex flex-col gap-[8px] justify-center items-center">
									{currentSelectedMovie && (
										<div className="truncate flex flex-wrap flex-col">
											<div className="flex items-center justify-between  w-[90%]">
												<p className={`text-[${color.text}]`}>{videoSrc?.title}</p>
												{/**
                       * <div className="flex items-center gap-5">
											<p
												className="text-gray-400"
												onClick={() => {
													//onChangeUpdateReport()
												}}
												role="button">
												<Tooltip title="Report" placement="top">
													<ReportIcon />
												</Tooltip>
											</p>
										</div>
                      */}
											</div>
										</div>
									)}
									{currentSelectedMovie && existMovie && verifyUrlMovie('youtube') ? (
										<div
											className="w-full main-video flex items-center justify-center"
											dangerouslySetInnerHTML={{ __html: videoSrc.html }}
										/>
									) : currentSelectedMovie && existMovie && verifyUrlMovie('tiktok') ? (
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<TikTokEmbed url={currentSelectedMovie} width={325} />
										</div>
									) : currentSelectedMovie && currentSelectedMovie[0] ? (
										<video src={currentSelectedMovie || ''} className="w-full " controls />
									) : movies && movies.length > 0 ? (
										<TextStyleBodyMedium>{''}</TextStyleBodyMedium>
									) : (
										<TextStyleBodyMedium>The creator of this festival doesn't add videos to it yet</TextStyleBodyMedium>
									)}

									{!profile && currentSelectedMovie && (
										<div className={`bg-white p-2 text-[${color.text}]`}>
											You need to{' '}
											<Link to="/register-profile" className="underline text-black hover:text-blue-400">
												create a social profile
											</Link>{' '}
											to interact
										</div>
									)}
									{data && data.status === 'Active' && user && currentSelectedMovie && profile && profile.user && (
										<Vote
											maxEntry={data.maxEntry}
											isDisabled={!user}
											action={sendVote}
											data={currentSelectedMovie}
											isFestival={true}
										/>
									)}
								</div>

								{data.status === 'Inactive' && profile && (
									<div className="mt-10 pb-20">
										<p className="bg-default p-2 text-white font-bold">
											This festival is already with its voting closed
										</p>
									</div>
								)}

								{data.status === 'New' && (
									<div className="mt-10 pb-20">
										<p className="bg-default p-2 text-white font-bold">
											This festival was created recently and soon it will be able to receive votes
										</p>
									</div>
								)}
							</div>

							<div className="w-full bg-white p-[8px]">
								<Carousel responsive={responsive}>
									{movies?.map((value: any, i: number) => (
										<div key={i}>
											<MovieCard
												isRanking={false}
												currentSelectedMovie={currentSelectedMovie}
												data={value}
												status={data.status}
												action={changeCurrentMovie}
												currentUserTotalLock={currentUserTotalLock}
												participants={getTotalParticipants}
												showModalUsers={showModalUsers}
												owner={data.owner}
											/>
										</div>
									))}
								</Carousel>
							</div>
						</div>
					)}
					{showReport && <ReportModal opened={showReport} setOpen={onChangeUpdateReport} currentEnum={0} />}
					{openAddMovie && data.status === 'AwaitingActivation' && (
						<AddMovie
							fetchExternal={externalFetch}
							setExternalFetch={setExternalFetch}
							fetchInser={fetchInser}
							isRanking={false}
							insertMovieInFestival={showSnackBar}
							isOpen={openAddMovie}
							close={handleClose}
							festivalData={data}
							setType={setType}
							title={'festival'}
						/>
					)}
					{activeFestivalState && data.status === 'AwaitingActivation' && (
						<ActiveFestival
							description={data.description}
							id={window.location.pathname.split('/')[2]}
							open={activeFestivalState}
							close={handleActivationModal}
							movies={movies}
							categorie={data.categoriesAndTags[0]}
						/>
					)}
					{editFestivalData && data.status === 'AwaitingActivation' && (
						<EditConstellationData
							action={showSnackBar}
							id={window.location.pathname.split('/')[2]}
							open={editFestivalData}
							setOpen={handleActivationModalEdit}
							data={data}
						/>
					)}
					{showBar && (
						<DscSnackBar type={typeSnackBar}>
							<p className="text-white ml-2 font-bold">
								{typeSnackBar === 'loading'
									? 'Block transaction'
									: typeSnackBar === 'done'
									? 'End transaction'
									: 'Error when transaction '}
							</p>
						</DscSnackBar>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default Details
