import React from 'react'
import { globals } from 'src/_helpers'

import { planksToTokens } from 'src/service/substrate/formated'
import './style.scss'
import Button from 'src/components/_Dsc/Button'
import { useNavigate } from 'react-router-dom'

interface Props {
	data: any
	changeCurrentData: (para: string) => void
}

export const MovieWinnerCard: React.FC<Props> = ({ data, changeCurrentData }) => {
	const navigate = useNavigate()
	const [videoSrc, setVideoSrc] = React.useState<any>([])
	const [length, setLength] = React.useState('')

	const getMovieData = async (link: string) => {
		await globals.getVideoSrc(link, setVideoSrc, 'youtube')
	}

	const redirect = (id: string) => {
		navigate('/details/' + id)
	}

	React.useEffect(() => {
		if (data && data.winners && data.winners[0]) {
			//let sum = 0
			setLength(data.voteMap[data.winners[0]].length)
			getMovieData(data.winners[0])
		}
	}, [data])

	return (
		<div
			className={` cursor-pointer overflow-hidden flex justify-between  flex-col gap-2 card-bg p-2 min-h-[250px] h-[250px] `}
			onClick={() => {
				changeCurrentData(data)
			}}>
			<div className="flex flex-col items-center justify-center w-full overflow-hidden">
				{videoSrc && videoSrc.title && <p className="overflow-hidden max-h-[50px]">{videoSrc.title}</p>}
				{videoSrc && videoSrc?.thumbnail_url && (
					<img src={videoSrc?.thumbnail_url} width="200px" height="140px" alt="icon" />
				)}
			</div>
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-2">
					<p className="number-body cursor-default">{planksToTokens(data.totalLockup)} Kinex Play</p>
					<p className="number-body cursor-default">{length} Votes</p>
				</div>
				<div className="flex items-center gap-2">
					<Button
						value={`Festival #${data.id}`}
						size="small"
						type="subtle"
						onClick={() => {
							redirect(data.id)
						}}
					/>
				</div>
			</div>
		</div>
	)
}
