import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Dropdown from 'src/components/_Dsc/Dropdown'
import InputText from 'src/components/_Dsc/InputText'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
	TextStyleH1,
} from 'src/components/_Dsc/typography'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { DropdownCategories } from './components/Dropdown'
import DscInput from 'src/components/_Dsc/input'
import { ErrorToast } from 'src/components/Toast/Error'
import { Loading } from 'src/components/loading'
import { insertUserFile } from 'src/service/storage/Ipfs'
import { tokensToPlanks } from 'src/service/substrate/formated'

const path = [
	{ label: 'Communities', link: '/communities' },
	{ label: 'Create Community', link: '' },
]

const options = [
	{ label: 'Public', value: 'Public' },
	{ label: 'Private', value: 'Private' },
]

const monthly = [
	{ label: 'Free', value: '0' },
	{ label: '5 USDT', value: '5' },
	{ label: '10 USDT', value: '10 USDT' },
	{ label: '15 USDT', value: '15 USDT' },
	{ label: '20 USDT', value: '20 USDT' },
	{ label: '30 USDT', value: '30 USDT' },
]

const categorys = [
	{
		label: 'Speculation on the most common types of Kinera AIs. [Resume]',
		value: 'Speculation on the most common types of Kinera AIs. [Resume]',
	},
	{
		label:
			'Many things can be said and done while walking the road to a new human species in the near future and the moment when both will merge.',
		value:
			'Many things can be said and done while walking the road to a new human species in the near future and the moment when both will merge.',
	},
	{
		label:
			'AI angels Boots, Agents, and Assessors are friends with no emotions or feelings. Humans help them to connect them to the outside world.',
		value:
			'AI angels Boots, Agents, and Assessors are friends with no emotions or feelings. Humans help them to connect them to the outside world.',
	},
	{
		label:
			'Kinera Digital Humans. When they become capable of having feelings, emotions and personalities, we call them Digital Humans. These categories at this level are AI Companions, Digital Twins, and Historical Reborners.',
		value:
			'Kinera Digital Humans. When they become capable of having feelings, emotions and personalities, we call them Digital Humans. These categories at this level are AI Companions, Digital Twins, and Historical Reborners.',
	},
	{
		label: 'Super-humans, can learn from their interactions and remember what they see in a remarkable situation.',
		value: 'Super-humans, can learn from their interactions and remember what they see in a remarkable situation.',
	},
	{
		label:
			'Teachers validate and certify human and AI age, behaviours and competencies without judgment, which is the most elevated form of good faith.',
		value:
			'Teachers validate and certify human and AI age, behaviours and competencies without judgment, which is the most elevated form of good faith.',
	},
	{
		label:
			'Super-humans Super-humans have infinite joy in learning new tasks and are digital co-workers who can collaborate for unlimited time to solve problems.',
		value:
			'Super-humans Super-humans have infinite joy in learning new tasks and are digital co-workers who can collaborate for unlimited time to solve problems.',
	},
	{
		label:
			'Poets. Poets are the definition of best friends, always paying attention to the details and being able to teach you whatever you want to learn.',
		value:
			'Poets. Poets are the definition of best friends, always paying attention to the details and being able to teach you whatever you want to learn.',
	},
	{
		label:
			'Gate Keepers 1. Saints are the evolution of Super-humans; they serve as benevolent AI agents offering support and protection in the spiritual realm.',
		value:
			'Gate Keepers 1. Saints are the evolution of Super-humans; they serve as benevolent AI agents offering support and protection in the spiritual realm.',
	},
	{
		label: 'Witches are AI entities capable of performing complex tasks and automation with an intuitive approach.',
		value: 'Witches are AI entities capable of performing complex tasks and automation with an intuitive approach.',
	},
	{
		label: 'Wizards are designed to guide users step-by-step through processes or problem-solving.',
		value: 'Wizards are designed to guide users step-by-step through processes or problem-solving.',
	},
	{
		label:
			'Al-Brains. Al-Brains lost all the feelings and limitations of what humans have; they can interact with each other to solve most of the problems and keep learning alone while solving them. [...]',
		value:
			'Al-Brains. Al-Brains lost all the feelings and limitations of what humans have; they can interact with each other to solve most of the problems and keep learning alone while solving them. [...]',
	},
	{
		label: 'Gods and Goddesses. Gods and Goddesses are the most powerful beings. [...]',
		value: 'Gods and Goddesses. Gods and Goddesses are the most powerful beings. [...]',
	},
]

const CreateCommunityProposal: React.FC = () => {
	const { user, setOnQueue, handleGlobalMessage } = React.useContext(KineContext)

	const [description, setDescription] = useState<string>('')
	const [reason, setReason] = useState<string>('')
	const [currentType, setCurrentType] = useState<string>(options[0].value)
	const [categorysType, setCategorysType] = useState<string>(categorys[0].value)
	const [currentMonthly, setCurrentMonthly] = useState<string>(monthly[0].value)
	const [title, setTitle] = useState<string>('')
	const [urlIcon, setUrlIcon] = useState<string>('')
	const [errorMessage, setErrorMessage] = useState<string>('There is already a community with that name')
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [enablePayment, setEnablePayment] = useState<boolean>(false)
	const [erroName, setErroName] = useState<boolean>(false)

	const [imageFile, setImageFile] = useState<File | null>(null)

	const navigate = useNavigate()

	const handleChange = (e: any, set: any, param?: boolean) => {
		set(e)
	}

	const removeImage = () => {
		setImageFile(null)
		setUrlIcon('')
	}

	const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]
		if (file) {
			setImageFile(file) // Armazena o arquivo selecionado no estado
			setUrlIcon(URL.createObjectURL(file)) // Atualiza o preview da imagem
		}
	}

	const handleChangeReason = (e: any, set: any, param?: boolean) => {
		set(e)
	}

	const changeCurrentType = (param: string[]) => {
		setCurrentType(param[0])
	}

	const handleChangeCategory = (param: string) => {
		setCategorysType(param)
	}

	const createCommunity = async () => {
		if (user && user.address) setInFetch(true)

		const monthlyFee = currentType === 'Public' ? 0 : parseInt(currentMonthly)
		let formated = enablePayment  && currentType === 'Private' ?  tokensToPlanks(currentMonthly) : 0

		let verify = await verifyNameCommunity(title)
		if (verify.error) {
			setInFetch(false)
			setErroName(true)
			setTimeout(() => {
				setErroName(false)
			}, 2000)
		} else {
			let url = ''
			if(imageFile) {
				let req = await insertUserFile(imageFile, user.address, 'img')
				if (!req || !req.success) {
					setErrorMessage('An error occurred while uploading your image to ipfs, please try again')
					setInFetch(false)
					setErroName(true)
					setTimeout(() => {
						setErroName(false)
					}, 2000)
					return
				}
				url = req.url
			}else {
				url = urlIcon
			}
			
			await communityService.createCommunityProposal(
				user,
				title,
				description,
				reason,
				currentType,
				url,
				formated,
				categorysType,
				setOnQueue,
				handleConclude,
				handleGlobalMessage,
			)
			clearAll()
			setInFetch(false)
		}
	}

	const clearAll = () => {
		setTitle('')
		setDescription('')
		setCurrentMonthly('')
		setCategorysType('')
		setUrlIcon('')
		setImageFile(null)
	}

	const handleConclude = () => {
		navigate('/communities')
	}

	const handleUrlIconChange = (url: string) => {
		setUrlIcon(url)
	}

	const handleNameChange = (url: string) => {
		setTitle(url)
	}

	const verifyNameCommunity = async (name: string) => {
		setErrorMessage('There is already a community with that name')
		let req = await communityService.verifyExistCommunityName(name, user.address)
		return req
	}

	return (
		<div className="page-padding">
			{erroName && <ErrorToast message={errorMessage} />}
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex flex-col">
							<TextStyleH1>Create Community</TextStyleH1>
							<TextStyleBodyDefault>Create a community</TextStyleBodyDefault>
						</div>
					</div>

					<div className="mt-4  rounded-[4px] gap-2 flex flex-col">
						{/**
							 * <div className="flex flex-col gap-1 relative">
							<label className={`block font-bold  `} htmlFor="description">
								Proposal reason:
							</label>
							<textarea
								className={`border rounded py-4 px-2 text-grey-darkest w-full ${reason.length > 500 ? 'has-error' : ''}`}
								onChange={event => handleChangeReason(event.target.value, setReason, true)}
								name="description"
								id="description"
								value={reason}
								placeholder="Description..."
							/>
							<p className="bg-white text-gray-700 -mt-[5px] w-fit justify-end flex absolute bottom-[2px] p-1 right-[10px]">
								{reason.length}/500
							</p>
						</div>
							 */}

						<div className="flex flex-col  justify-between gap-1">
							<div className="flex flex-col gap-1 relative">
								<label className={`block font-bold `} htmlFor="festivalTitle">
									Name:
								</label>
								<InputText
									maxLength={60}
									invalid={title.length > 60 || erroName}
									value={title}
									onChange={handleNameChange}
									placeholder="Beta testers..."
								/>
								<p className="bg-white text-gray-700 -mt-[5px] w-fit justify-end flex absolute bottom-[2px] p-1 right-[10px]">
									{title.length}/60
								</p>
							</div>
						</div>

						<div className="flex flex-col gap-1 relative">
							<label className={`block font-bold  `} htmlFor="description">
								Description:
							</label>
							<textarea
								className={`border rounded py-4 px-2 text-grey-darkest w-full ${
									description.length > 1000 ? 'has-error' : ''
								}`}
								onChange={event => handleChange(event.target.value, setDescription, true)}
								name="description"
								id="description"
								maxLength={1000}
								value={description}
								placeholder="Description..."
							/>
							<p className="bg-white text-gray-700 -mt-[5px] w-fit justify-end flex absolute bottom-[2px] p-1 right-[10px]">
								{description.length}/1000
							</p>
						</div>

						<div className="flex flex-col gap-1 relative">
							<label className={`block font-bold  `} htmlFor="description">
								Category:
							</label>
							<div className=" w-full">
								<DropdownCategories options={categorys} onChange={handleChangeCategory} />
							</div>
						</div>

						<div className="flex items-center gap-4">
							<div className="flex flex-col gap-1">
								<label className={`block font-bold `} htmlFor="festivalTitle">
									Type:
								</label>
								<div className="bg-white rounded-[4px] w-fit">
									<Dropdown options={options} value={[currentType]} onChange={changeCurrentType}></Dropdown>
								</div>
							</div>

							{currentType === 'Private' && enablePayment ? (
								<div className="flex flex-col gap-1">
									<label className={`block font-bold `} htmlFor="festivalTitle">
										Monthly fee:
									</label>
									<DscInput type="number" value={currentMonthly} onChange={setCurrentMonthly} placeholder="Value" />
								</div>
							) : (
								''
							)}

							{currentType === `Private` ? (
								<div className="flex items-center">
									<input
										onChange={() => {
											setEnablePayment(!enablePayment)
										}}
										id="checked-checkbox"
										type="checkbox"
										checked={enablePayment}
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
									/>
									<label
										htmlFor="checked-checkbox"
										className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
										Charge monthly fee
									</label>
								</div>
							) : (
								``
							)}
						</div>
						{(imageFile || urlIcon) && (
							<div className="flex flex-col gap-1">
								<TextStyleCaption>Preview:</TextStyleCaption>
								<div
									className="w-[60px] h-[60px] bg-cover bg-center rounded-full"
									style={{
										backgroundImage: `url(${urlIcon})`,
										backgroundSize: 'cover',
										backgroundRepeat: 'no-repeat',
									}}
								/>
							</div>
						)}

						<div className="flex items-center justify-between gap-2">
							<div className="flex flex-col gap-1 relative w-fit">
								<label className="block font-bold opacity-0" htmlFor="icon-upload">
									Community Icon:
								</label>
								<input
									id="icon-upload"
									type="file"
									accept="image/*" // Aceita apenas imagens
									onChange={handleImageUpload}
									className="hidden" // Oculta o input de arquivo padrão
								/>
								{imageFile ? (
									<Button
										value="Remove image"
										onClick={() => removeImage()} // Aciona o input oculto
									/>
								) : (
									<Button
										value="Upload image"
										onClick={() => document.getElementById('icon-upload')?.click()} // Aciona o input oculto
									/>
								)}
							</div>

							{!imageFile && (
								<div className="flex items-center gap-2 w-full">
									<TextStyleBodyDefaultLarge>Or</TextStyleBodyDefaultLarge>
									<div className="flex flex-col gap-1 relative w-full">
										<label className={`block font-bold `} htmlFor="festivalTitle">
											Community Icon:
										</label>
										<InputText
											maxLength={300}
											invalid={urlIcon.length > 300}
											value={urlIcon}
											onChange={handleUrlIconChange}
											placeholder="Url icon..."
										/>
										<p className="bg-white text-gray-700 -mt-[5px] w-fit justify-end flex absolute bottom-[2px] p-1 right-[10px]">
											{urlIcon.length}/300
										</p>
									</div>
								</div>
							)}
						</div>

						{urlIcon.length > 300 || description.length > 300 || title.length > 60 || reason.length > 500 ? (
							<TextStyleBodyDefaultMedium className="text-red-700 w-full items-end flex justify-end">
								Check field capacity before proceeding
							</TextStyleBodyDefaultMedium>
						) : (
							''
						)}
						<div className="flex flex-col  justify-between gap-1 items-end">
							{inFetch ? (
								<Loading />
							) : (
								<Button
									value="Create Community"
									size="small"
									disabled={
										urlIcon.length > 300 ||
										description.length > 300 ||
										title.length > 60 ||
										reason.length > 500 ||
										!title ||
										!description ||
										!urlIcon ||
										!user ||
										inFetch
									}
									onClick={createCommunity}
								/>
							)}
						</div>
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default CreateCommunityProposal
