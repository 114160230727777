import React, { useState } from 'react'
import Modal from '../_Dsc/Modal'
import KineContext from 'src/context/KineContext'
import Button from '../_Dsc/Button'
import { Loading } from '../loading'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium, TextStyleCaption } from '../_Dsc/typography'
import axios from 'axios'
import Dropdown from '../_Dsc/Dropdown'

const options = [
	{
		label: 'Kinera',
		value: 'kineragpt',
		description: 'Best for answering questions about the platform and the objectives of the kinera project.',
	},
	{
		label: 'Arena model',
		value: 'arena-model',
		description: 'Ideal for fast, responsive real-time interactions and dynamic content.',
	},
	{
		label: 'gemma2 latest',
		value: 'gemma2:latest',
		description: 'Best for detailed conversations and contextual responses.',
	},
	{
		label: 'llama3.1 latest',
		value: 'llama3.1:latest',
		description: 'Great for precise analysis and large-scale content generation.',
	},
	{
		label: 'llama3.2 latest',
		value: 'llama3.2:latest',
		description: 'Focused on natural style and engaging language.',
	},
]

interface Option {
	label: string
	value: string
	description: string
}

const defaultHelp = ['What is the Kinera Network?', 'What are constellations?', 'What are rankings?']

export const GPT: React.FC = () => {
	const { openHelp, swapCurrentHelp } = React.useContext(KineContext)
	const [inputText, setInputText] = useState('')
	const [selectedOption, setSelectedOptions] = useState(options[1].value)
	const [selectedOptionDetail, setSelectedOptionsDetail] = useState<Option>(options[1])
	const [responses, setResponses] = useState<string[]>([])
	const [loading, setLoading] = useState(false)
	const [isHelp, setIsHelp] = useState(false)

	const postChatCompletion = async (param?: string) => {
		try {
			setInputText('')
			setLoading(true)
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: selectedOptionDetail.value,
					messages: [
						{
							role: 'user',
							content:param ? param : inputText,
						},
					],
					files: [],
				},
				{
					headers: {
						Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNjYzgxOWNmLWExZDktNDZlZC1hYWExLWMwNThkYTVjZGNkYSJ9.-OQ5I6C6jN4WE9wPZc0nxHp56tzt-qEqS77nz_2-SeU`,
						'Content-Type': 'application/json',
					},
				},
			)

			setResponses(prev => [...prev, `Question: ${param ? param : inputText}`, `Answer: ${response.data.choices[0].message.content}`])
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}

	const handleSelectOptions = (option: string) => {
		let filter = options.filter((val: Option) => val.value === option[0])
		setSelectedOptionsDetail(filter[0])
		setSelectedOptions(option[0])
	}

	const handleSearchByHelp = (option: string) => {
		setInputText(option)
		postChatCompletion(option)
	}

	const onOpenHelp = () => {
		setIsHelp(true)
		setSelectedOptions(options[0].value)
		setSelectedOptionsDetail(options[0])
		swapCurrentHelp(true)
	}

	const closeModal = () => {
		swapCurrentHelp(false)
		setIsHelp(false)
	}

	const openModal = () => {
		swapCurrentHelp(true)
		setSelectedOptions(options[1].value)
		setSelectedOptionsDetail(options[1])
	}

	React.useEffect(() => {
		setResponses([])
	},[openHelp])

	return (
		<div className="relative">
			<Modal
				title="Kinera interact"
				open={openHelp}
				onCancel={() => {
					closeModal()
				}}
				onClose={() => {
					closeModal()
				}}
				cancelLabel="Close"
				size="xl">
				<div className="p-4 h-full flex bg-[#ededed]">
					<div className="p-4 bg-white gap-4 flex flex-col max-w-[40%] w-[40%] overflow-hidden">
						<div className="relative w-full">
							<Dropdown
								options={options}
								value={[selectedOption]}
								onChange={(e: any) => {
									handleSelectOptions(e)
								}}></Dropdown>
						</div>
						<div>
							<TextStyleBodyDefaultMedium>{selectedOptionDetail.description}</TextStyleBodyDefaultMedium>
						</div>
					</div>
					<div className="w-full justify-end items-center flex flex-col">
						{responses && responses.length ? (
							<div className="overflow-y-auto p-2 w-full">
								{responses.map((response, index) => (
									<div key={index} className="flex flex-col w-full">
										{response.includes('Question:') ? (
											<div className="w-full flex items-end  justify-end ">
												<div className="w-fit p-2 bg-[#F9F9F9] rounded-[100px]">
													<TextStyleBodyDefaultMedium>
														{response.includes('Question:') ? response.split('Question: ')[1] : ''}
													</TextStyleBodyDefaultMedium>
												</div>
											</div>
										) : (
											''
										)}

										{response.includes('Answer:') ? (
											<div className="p-2 w-full items-start justify-start flex rounded-[10px]">
												<div className="flex w-full h-full items-start gap-2">
													<img
														src="https://alpha.kinera.network/static/media/kinera_.aa99fcdcf04e75b583bf.webp"
														className="w-[30px] rounded-full bg-white p-1"
													/>
													<TextStyleBodyDefaultMedium>
														{response.includes('Answer:') ? response.split('Answer: ')[1] : ''}
													</TextStyleBodyDefaultMedium>
												</div>
											</div>
										) : (
											''
										)}
									</div>
								))}
							</div>
						) : (
							''
						)}
						{loading ? <Loading /> : ''}
						<div className=" w-[90%] rounded-[10px] mt-4 flex flex-col gap-2">
							<div className="flex items-center gap-2">
								{isHelp && !responses.length && !loading
									? defaultHelp.map((val: string, key: number) => (
											<div key={key} className="w-fit p-2 rounded-[10px] bg-white hover:bg-gray-100 cursor-pointer" onClick={() => {handleSearchByHelp(val)}}>
												<TextStyleCaption>{val}</TextStyleCaption>
											</div>
									  ))
									: ''}
							</div>
							<div className="p-4 rounded-[4px] bg-white flex flex-col gap-1 items-end justify-end">
								<textarea
									maxLength={300}
									id="description"
									rows={4}
									value={inputText}
									onChange={e => setInputText(e.target.value)}
									className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
									placeholder="Start typing"></textarea>
								<Button
									value="Send"
									size="small"
									onClick={postChatCompletion}
									disabled={loading || inputText.trim() === ''}
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<div className="w-full flex items-center justify-center">
				<div className="fixed bottom-[40px] gap-2 flex transform z-[9999999999]">
					<Button
						size="small"
						icon="robot"
						iconOnly
						value="robot"
						onClick={() => {
							openModal()
						}}
					/>
					<Button
						size="small"
						icon="help"
						iconOnly
						value="robot"
						onClick={() => {
							onOpenHelp()
						}}
					/>
				</div>
			</div>
		</div>
	)
}
