import React, { useContext, useEffect, useState } from 'react'

import { RankingService } from '../../service/ranking/index'
import './style.scss'
import KineContext from '../../context/KineContext'
import { globals } from '../../_helpers'
import AddMovie from '../../components/addMovieModal'

import SkeletonDetail from '../details/Skelleton'
import { TikTokEmbed } from 'react-social-media-embed'
import ViewMovieModal from './components/viewMovieModal'
import { getChannelData } from '../../service/youtube'
import { planksToTokens, tokensToPlanks } from 'src/service/substrate/formated'
import DscSnackBar from 'src/components/_Dsc/snackbar'
import { isByProfile } from 'src/constants/rankings'
import { MoviesinList } from './components/movies'
import { TextStyleBodyMedium, TextStyleH1 } from 'src/components/_Dsc/typography'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'

 const RankingDetails: React.FC = () => {
		const [currentId, setCurrentId] = useState('')
		const { user, apiState } = useContext(KineContext)
		const [rankingDetail, setRankingDetail] = useState<any | null>(null)
		const [openAddMovie, setOpenAddMovie] = useState<boolean>(false)
		const [isFetch, setIsFetch] = useState<boolean>(false)
		const [movies, setMovies] = useState<any | null>(null)
		const [selectedMovie, setSelectedMovie] = useState<any>([])
		const [videoSrc, setVideoSrc] = useState<any>({})

		const [externalFetch, setExternalFetch] = useState<boolean>(false)
		const [fetch, setFetch] = useState<boolean>(true)
		const [openMovie, setOpenMovie] = useState<boolean>(false)
		const [showMovieUrl, setShowMovieUrl] = useState<any>([])
		const [byProfile, setByProfile] = useState<boolean>(false)
		const [showBar, setShowBar] = useState(false)
		const [typeSnackBar, setTypeSnackbar] = useState('')

		const getDetails = async () => {
			try {
				let id = window.location.pathname.split('/')[3]
				if (id) {
					setFetch(true)
					setCurrentId(id)
					let request = await RankingService.getRankingListsById(id, apiState)
					setRankingDetail(request)
					if (request && request.name && isByProfile.includes(request.name)) {
						setByProfile(true)
					}
					if (request.moviesInList && request.moviesInList.length) {
						const sortedMovies = request.moviesInList.slice().sort((a: any, b: any) => {
							const totalLockA = totalLockMovie(a)
							const totalLockB = totalLockMovie(b)

							const numericTotalLockA: any = totalLockA
							const numericTotalLockB: any = totalLockB

							return numericTotalLockB - numericTotalLockA
						})

						setMovies(sortedMovies)
					}
					setFetch(false)
				}
			} catch (error) {
				console.log(error)
			}
		}

		const showSnackBar = async (param: boolean, type: string, isMovie: string = '', amount: string = '') => {
			setSelectedMovie('')
			setIsFetch(false)
			if (type !== 'done' && type !== 'error') {
				setShowBar(param)
				setTypeSnackbar(type)
			} else {
				setTypeSnackbar(type)
				if (!isMovie) {
					await getDetails()
				} else {
					await updateMoviesVotes(isMovie, amount)
				}
				const timer = setTimeout(async () => {
					setShowBar(false)
				}, 4000)
				return () => clearTimeout(timer)
			}
		}

		const updateMoviesVotes = (movieId: string, amount: any) => {
			if (rankingDetail.votesByUser && rankingDetail.votesByUser[user.address]) {
				if (rankingDetail.votesByUser[user.address][movieId]) {
					//console.log('ttal', planksToTokens(rankingDetail.votesByUser[user.address][movieId].lockedAmount))
					let formated: any = planksToTokens(rankingDetail.votesByUser[user.address][movieId].lockedAmount)
					let attr = (rankingDetail.votesByUser[user.address][movieId].lockedAmount =
						parseInt(formated) + parseInt(amount))
					rankingDetail.votesByUser[user.address][movieId].lockedAmount = tokensToPlanks(attr)
				} else {
					rankingDetail.votesByUser[user.address][movieId] = {
						movieId: movieId,
						lockedAmount: tokensToPlanks(amount),
						conviction: 'None',
						unlockBlock: '0',
					}
				}
			} else {
				rankingDetail.votesByUser = rankingDetail.votesByUser || {}
				rankingDetail.votesByUser[user.address] = {}
				rankingDetail.votesByUser[user.address][movieId] = {
					movieId: movieId,
					lockedAmount: tokensToPlanks(amount),
					conviction: 'None',
					unlockBlock: '0',
				}
			}
			//console.log('rankingDetail', rankingDetail)
			//console.log('amount', amount)

			return rankingDetail
		}

		const sendVote = async (movieId: string, data: any) => {
			setIsFetch(true)
			await RankingService.voteInMovie(currentId, movieId, data, user, apiState, showSnackBar)
			setSelectedMovie(null)
			setIsFetch(false)
		}

		const handleClose = () => {
			setOpenAddMovie(false)
		}

		const changeCurrentMovie = async (movie: any) => {
			if (movie && movie.includes('youtube.com')) {
				setSelectedMovie(movie)

				return globals.getVideoSrc(movie, setVideoSrc, 'youtube')
			} else if (movie.includes('tiktok.com')) {
				setSelectedMovie(movie)
				globals.getVideoSrc(movie, setVideoSrc, 'tiktok')
			}
		}

		const loaderCurrentMovieVotes = (movieId: string) => {
			let totalVotes = 0
			for (const userKey in rankingDetail.votesByUser) {
				if (rankingDetail.votesByUser.hasOwnProperty(userKey)) {
					const userVotes = rankingDetail.votesByUser[userKey]
					totalVotes = userVotes.length
				}
			}
			return totalVotes
		}

		const totalLockMovie = (movieId: string) => {
			let totalVotes = 0
			if (!rankingDetail || !rankingDetail.votesByUser) return
			let userVotes: any = []
			for (userVotes of Object.values(rankingDetail.votesByUser)) {
				let voteDetail: any = []
				for (voteDetail of Object.values(userVotes)) {
					if (voteDetail.movieId === movieId) {
						const lockedAmount = planksToTokens(voteDetail.lockedAmount)
						totalVotes += lockedAmount
					}
				}
			}
			return totalVotes
		}

		const currentUserTotalLock = (movieId: string) => {
			let totalVotes = 0
			if (
				user &&
				user.address &&
				rankingDetail &&
				rankingDetail.votesByUser &&
				rankingDetail.votesByUser[user.address]
			) {
				let userVotes: any = []
				for (userVotes of Object.values(rankingDetail.votesByUser[user.address])) {
					if (userVotes.movieId === movieId) {
						totalVotes += planksToTokens(userVotes.lockedAmount)
					}
				}
			}

			return totalVotes
		}

		const showModalMovie = (movie: any) => {
			setOpenMovie(true)
			if (movie.includes('youtube.com')) {
				setShowMovieUrl(movie)
				return globals.getVideoSrc(movie, setVideoSrc, 'youtube')
			} else if (movie.includes('tiktok.com')) {
				setShowMovieUrl(movie)
				globals.getVideoSrc(movie, setVideoSrc, 'tiktok')
			}
		}

		const verifyUrlMovie = (url: string) => {
			try {
				if (showMovieUrl.includes(url)) {
					return true
				} else {
					return false
				}
			} catch (e) {
				console.log(e)
			}
		}

		const verifyUnstake = (url: string) => {
			if (rankingDetail && rankingDetail.votesByUser && user && user.address) {
				let userVotes = rankingDetail.votesByUser[user.address]

				if (userVotes) {
					let totalValue = 0

					if (userVotes[url]) {
						totalValue += planksToTokens(userVotes[url].lockedAmount)
					}

					return totalValue
				} else {
					return 0
				}
			} else {
				return 0
			}
		}

		const getByProfile = async (url: string) => {
			const channelData = await getChannelData(url)
			return channelData
		}

		useEffect(() => {
			if (apiState) {
				getDetails()
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [apiState])

		return (
			<div className="page-padding mb-[90px]">
				<CardInner>
					<CardContainer>
						{showMovieUrl && openMovie && verifyUrlMovie('youtube') && (
							<ViewMovieModal open={openMovie} setOpen={setOpenMovie}>
								<div
									className="w-full main-video flex items-center justify-center"
									dangerouslySetInnerHTML={{ __html: videoSrc.html }}
								/>
							</ViewMovieModal>
						)}
						{showMovieUrl && openMovie && verifyUrlMovie('tiktok') && (
							<ViewMovieModal open={openMovie} setOpen={setOpenMovie}>
								<TikTokEmbed url={videoSrc.link} width={325} />
							</ViewMovieModal>
						)}
						{rankingDetail && !fetch ? (
							<div className="flex flex-col gap-[8px] ">
								<div className="flex items-center justify-center  gap-[8px] flex-col">
									<TextStyleH1>{rankingDetail && rankingDetail.name && rankingDetail.name}</TextStyleH1>
									<TextStyleBodyMedium>
										{rankingDetail && rankingDetail.description && rankingDetail.description}
									</TextStyleBodyMedium>
									{user && user.address && (
										<Button
											value="New Entry"
											onClick={() => {
												setOpenAddMovie(true)
											}}></Button>
									)}
								</div>
								<div className="flex flex-col ">
									{movies && movies.length && (
										<MoviesinList
											rankingData={rankingDetail}
											list={movies}
											sendVote={sendVote}
											loaderCurrentMovieVotes={loaderCurrentMovieVotes}
											movies={movies}
											selectedMovie={selectedMovie}
											isFetch={isFetch}
											videoSrc={videoSrc}
											changeCurrentMovie={changeCurrentMovie}
											totalLockMovie={totalLockMovie}
											showModalMovie={showModalMovie}
											verifyUnstake={verifyUnstake}
											byProfile={byProfile}
											getByProfile={getByProfile}
											currentUserTotalLock={currentUserTotalLock}
										/>
									)}
								</div>
							</div>
						) : (
							<SkeletonDetail />
						)}
						{openAddMovie && (
							<AddMovie
								fetchExternal={externalFetch}
								setExternalFetch={setExternalFetch}
								fetchInser={false}
								isRanking={true}
								insertMovieInFestival={showSnackBar}
								isOpen={openAddMovie}
								close={handleClose}
								setType={() => {}}
								title={'Ranking'}
								type={'ranking'}
								byProfile={byProfile}
							/>
						)}
						{showBar && (
							<DscSnackBar type={typeSnackBar}>
								<p className="text-white ml-2">
									{typeSnackBar === 'loading'
										? 'Block transaction'
										: typeSnackBar === 'done'
										? 'End transaction'
										: typeSnackBar === 'error'
										? 'Error when transaction'
										: ''}
								</p>
							</DscSnackBar>
						)}
					</CardContainer>
				</CardInner>
			</div>
		)
 }

 export default RankingDetails