import React from 'react'
import './style.scss'
import KineContext from '../../../context/KineContext'

interface Props {
	text: string
	colorOption?: string
}

const TitleH1: React.FC<Props> = ({ text, colorOption }) => {
	const { color } = React.useContext(KineContext)
	return <h1 className={`dsc__text__h1 text-[${colorOption ? colorOption : color.text}]`}>{text}</h1>
}

export default TitleH1
