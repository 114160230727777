const colors = {
	black: 'black',
	white: 'white',

	bluePrimary: '#0654DF',
	blueLight: '#0053e4',
	blueCircle: '#0554DF',
	blueSecondary: '#0d54df',
	blueProgressBar: '#003EAB',
	blueSecondaryLight: ' rgba(235, 240, 252, 0.48)',

	pinkElo: '#FD5ECD',

	greenCircle: '#4ED886',
	greenLight: '#E4F5EC',
	greenLightLabelStatus: '#78CF9F',
	greenProgressBar: '#406751',
	greenBackGround: '#66A582',

	grayBackGroundDisable: '#00000029',
	grayBoxShadow: '#00000039',
	grayMouseHover: '#e9e4e459',
	grayTitle: '#233043',
	graySubTitle: '#838B95',
	grayDisabled: '#f4f8fb',
	grayBorder: '#efefef',
	graySubBorder: '#edeff1',
	grayPlaceholder: '#848b94',
	grayLight: '#ECEFF1',
	grayTitleSecund: '#828b95',
	grayProgressBar: '#c3ccd5',
	Surface_Off: '#F7F6F6',
	Border_Disabled: '#DDDDDD',

	yellowCircle: '#FED83E',
	yellowProgressBar: '#B59834',
	yellowLightLabelStatus: '#F1CA45',

	redLight: 'rgba(255, 224, 245, 0.48)',
	redSubLight: '#FDEFF0',
	redLightLabelStatus: '#EA6D6F',
	redProgressBar: '#AF5253',

	blackTitle: '#212121',
}

export default colors
