import { createContext, SetStateAction } from 'react'
interface userData {
	user: any
	setUser: (active: {}) => void

	handleSetGlobalFetch: () => void
	isModerator: boolean
	setIsModerator: (value: boolean) => void

	apiState: any

	color: any
	setColor: (value: SetStateAction<any>) => void
	onClicked: any
	setOnclicked: (value: SetStateAction<any>) => void
	globalToast: boolean
	setGlobalToast: (value: SetStateAction<any>) => void
	profile: any
	setGlobalProfile: (value: SetStateAction<any>) => void
	globalSnack: boolean
	setGlobalSnack: (value: SetStateAction<any>) => void
	openHelp: boolean
	swapCurrentHelp: (value: SetStateAction<any>) => void
	showFullMenu: boolean
	setShowFullMenu: (value: SetStateAction<any>) => void
	notifications: any
	setNotifications: (value: SetStateAction<any>) => void
	getAllNotifyUser: () => void
	currentLangues: any
	setCurrentLanguage: (value: SetStateAction<any>) => void
	optionsLanguage: any
	currentTab: string
	verifyCurrentUserStorage: boolean
	setCurrentUserStorage: (value: boolean) => void
	storageUsed: string
	setStorageUsed: (value: string) => void
	onQueue: boolean
	setOnQueue: (value: boolean) => void
	globalMessage: string
	handleGlobalMessage:(param:string) => void
}
const KineContext = createContext({} as userData)

export default KineContext
