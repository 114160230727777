import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from 'src/components/_Dsc/Modal'
import { TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'
import KineContext from 'src/context/KineContext'
import { insertUserFile } from 'src/service/storage/Ipfs'
import Button from 'src/components/_Dsc/Button'

interface Props {
	setShowModalUpload: (param: boolean) => void
	showModalUpload: boolean
}

export const UploadFile: React.FC<Props> = ({ setShowModalUpload, showModalUpload }) => {
	const { user, handleGlobalMessage, setOnQueue } = React.useContext(KineContext)
	const [type, setType] = useState('')
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const { id } = useParams()

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			setSelectedFile(event.target.files[0])
		}
	}

	const handleInserFile = async () => {
		if (selectedFile && id) {
			handleGlobalMessage('Your file is being processed and will soon be available')
			setOnQueue(true)
			await insertUserFile(selectedFile, user.address, 'community_file', id)
			setShowModalUpload(false)
		}
	}

	return (
		<Modal
			onCancel={() => {
				setShowModalUpload(false)
			}}
			onClose={() => {
				setShowModalUpload(false)
			}}
			onConfirm={handleInserFile}
			confirmDisabled={!selectedFile}
			cancelLabel="Cancel"
			confirmLabel="Upload"
			open={showModalUpload}
			title={'Upload file to community'}>
			<div className="flex flex-col gap-2">
				<TextStyleBodyDefaultMedium>Select File type:</TextStyleBodyDefaultMedium>
				<div className="flex items-center gap-2">
					<div className="flex items-center mb-4">
						<input
							id="radio-image"
							type="radio"
							value="img"
							name="file-type"
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
							onChange={() => setType('img')}
						/>
						<label htmlFor="radio-image" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
							Image
						</label>
					</div>
					<div className="flex items-center mb-4">
						<input
							id="radio-video"
							type="radio"
							value="video"
							name="file-type"
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
							onChange={() => setType('video')}
						/>
						<label htmlFor="radio-video" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
							Video
						</label>
					</div>
					<div className="flex items-center mb-4">
						<input
							id="radio-file"
							type="radio"
							value="file"
							name="file-type"
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
							onChange={() => setType('file')}
						/>
						<label htmlFor="radio-file" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
							File (PDF, CSV, PPTX)
						</label>
					</div>
				</div>
				{type && (
					<div className="flex items-center gap-2 mt-4">
						<Button value="Select File" size="small" onClick={() => document.getElementById('file-input')?.click()} />
						<input
							id="file-input"
							type="file"
							accept={type === 'img' ? 'image/*' : type === 'video' ? 'video/*' : '.pdf,.csv,.pptx, .txt'}
							className="hidden"
							onChange={handleFileChange}
						/>
					</div>
				)}
				{selectedFile && (
			<div className="mt-2 text-sm text-gray-700">
				<TextStyleCaption>Selected File: {selectedFile.name}</TextStyleCaption>
				<TextStyleCaption>Type: {selectedFile.type || 'Unknown type'}</TextStyleCaption>
			</div>
		)}
			</div>
		</Modal>
	)
}
