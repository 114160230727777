import React from 'react'
import { DsyButton, DsyButtonContent, DsyLabel, DsyUnderline } from './styles'
import { IButtonProps } from './interface'

export const Button: React.FC<IButtonProps> = ({
	value,
	size = 'medium',
	type = 'primary',
	icon,
	iconPosition,
	iconOnly,
	counter,
	disabled = false,
	onClick,
}) => {
	const onClickHandler = () => {
		if (!onClick) return

		onClick()
	}

	return (
		<DsyButton>
			<DsyButtonContent
				size={size}
				appearance={type}
				iconPosition={iconPosition}
				iconOnly={iconOnly}
				disabled={disabled}
				onClick={onClickHandler}
			>
				{icon && <i className="material-symbols-outlined">{icon}</i>}
				{!iconOnly && (
					<DsyLabel>
						{value}
						{type === 'link' && <DsyUnderline />}
					</DsyLabel>
				)}
			</DsyButtonContent>
		</DsyButton>
	)
}

export default Button
