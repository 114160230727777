import API from '../api'
import { ref, listAll } from 'firebase/storage'
export const insertFileInDB = async (file: any, userAddress: string, url: string) => {
	let payload = {
		user_id: userAddress,
		image: file.filename,
		model: file.model,
		width: file.width,
		height: file.height,
		steps: file.steps,
		url: url,
	}

	try {
		let req = await API.post(`/api/images/upload/`, payload)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

export const getUserFiles = async (fileName: string, userAddress: string) => {
	let payload = {
		user_id: userAddress,
		image: fileName,
	}

	try {
		let req = await API.post(`/api/images/byId`, payload)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

export const getAllUserFiles = async (userAddress: string) => {
	let payload = {
		user_id: userAddress,
	}

	try {
		let req = await API.post(`/api/images/get/public`, payload)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

export const getAllUserFilesPrivate = async (userAddress: string) => {
	let payload = {
		user_id: userAddress,
	}

	try {
		let req = await API.post(`/api/images/get/private`, payload)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

export const getAllUserFilesPublic = async (userAddress: string) => {
	let payload = {
		user_id: userAddress,
	}

	try {
		let req = await API.post(`/api/images/get/public`, payload)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

export const publishImageToPublic = async (file: any, userAddress: string) => {
	console.log('file', file)
	let payload = {
		user_id: userAddress,
		filename: file.image_path,
		id: file.id,
	}

	try {
		let req = await API.post(`/api/images/storage/change/public`, payload)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

export const changeImageToPrivate = async (file: any, userAddress: string) => {
	let payload = {
		user_id: userAddress,
		filename: file.image_path,
		id: file.id,
	}

	try {
		let req = await API.post(`/api/images/storage/change/private`, payload)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

export const removeUserFile = async (fileName: string, userAddress: string) => {
	let payload = {
		user_id: userAddress,
		image: fileName,
	}

	try {
		let req = await API.post(`/api/images/remove`, payload)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

export const fetchImages = async (
	user: any,
	serverAddress: string,
	setIfetch: (param: boolean) => void,
	setImages: any,
	key: boolean,
) => {
	try {
		const historyResponse = await fetch(`${serverAddress}/history?clientId=${user.address}`)
		const history = await historyResponse.json()
		setIfetch(true)
		const imageListPromises: any = []

		Object.keys(history).forEach(promptId => {
			const promptHistory = history[promptId]

			// Filter by client_id
			const clientId = promptHistory?.prompt?.[3]?.client_id
			if (clientId !== user.address) {
				return // Skip this prompt if the client_id doesn't match the logged user
			}

			if (promptHistory?.outputs) {
				for (const nodeId in promptHistory.outputs) {
					const nodeOutput = promptHistory.outputs[nodeId]

					const getImageData = async (image: any) => {
						const imageUrl = await fetch(
							`${serverAddress}/view?filename=${image.filename}&subfolder=${image.subfolder}&type=${
								image.type
							}&rand=${Math.random()}`,
						)
							.then(response => response.blob())
							.then(blob => URL.createObjectURL(blob))

						const imageFile = await fetch(
							`${serverAddress}/view?filename=${image.filename}&subfolder=${image.subfolder}&type=${
								image.type
							}&rand=${Math.random()}`,
						).then(response => response.blob())

						// Extracting prompt text, width, height, and steps
						let promptText = ''
						let width = 512
						let height = 512
						let steps = 10

						for (const key in promptHistory?.prompt?.[2]) {
							const node = promptHistory.prompt[2][key]
							if (node?.inputs?.text) {
								promptText = node.inputs.text
								break
							}
						}

						if (promptHistory?.prompt?.[2]?.[5]?.inputs) {
							width = promptHistory.prompt[2][5].inputs.width || 512
							height = promptHistory.prompt[2][5].inputs.height || 512
						}

						if (promptHistory?.prompt?.[7]?.inputs) {
							steps = promptHistory.prompt[7].inputs.steps || 10
						} else if (promptHistory?.prompt?.[3]?.inputs) {
							steps = promptHistory.prompt[3].inputs.steps || 10
						}

						// Determining the model
						const model = image.format === 'image/gif' && image.subfolder ? image.subfolder : 'dreamshaper_8'

						return {
							url: imageUrl,
							file: imageFile, // Add the actual file (blob)
							text: promptText,
							filename: image.filename,
							width: width,
							height: height,
							steps: steps, // Adding steps here
							model: model,
							format: image.format,
						}
					}

					if (key) {
						if (nodeOutput?.gifs) {
							const imagePromises = nodeOutput.gifs.map(getImageData)
							imageListPromises.push(...imagePromises)
						}
					} else {
						if (nodeOutput?.images) {
							const imagePromises = nodeOutput.images.map(getImageData)
							imageListPromises.push(...imagePromises)
						}
					}
				}
			}
		})

		const imageList = await Promise.all(imageListPromises)
		setImages(imageList)
		setIfetch(false)
	} catch (error) {
		console.error('Error fetching history:', error)
	}
}

