import React, { useState } from 'react'
import DscModal from '../../../../components/_Dsc/Modal'
import TextH3 from '../../../../components/_Dsc/typography/TextH3'

import './style.scss'

import { Loading } from 'src/components/loading'
import { planksToTokens } from 'src/service/substrate/formated'
import ReactPlayer from 'react-player'

interface Props {
	open: boolean
	setOpen(value: boolean): void
	data: any
}

interface Winner {
	address: string
	user: string
	amount: string
}

const WinnersModal: React.FC<Props> = ({ open, setOpen, data }) => {
	const [winningVotes, setWinningVotes] = useState<Winner[]>([])
	const [fetch, setFetch] = React.useState<boolean>(false)
	const [winnersMovie, setWinnersMovie] = React.useState<any>([])
	const openModal = () => {
		setOpen(!open)
	}

	const colorVariant = {
		color: '#000',
		fontSize: '17px',
	}
	const colorVariantTitle = {
		color: '#000',
		fontSize: '25px',
		fontWeight: 'bold',
	}

	/**
   * 
   *  const getUserVotes = async () => {
		setFetch(true)
		const id = window.location.pathname.split('/')[2]
		const req = await FestivalService.getVotesByConstellationWinners(id)
		setWinningVotes(req)

		//setVotes(req)
		setFetch(false)
	}
   */

	const findWinner = () => {
		setFetch(false)
		let allData: any = []

		if (data && data.winners) {
			let winners = data.winners
			setWinnersMovie(winners)
			for (const movie in winners) {
				if (data && data.voteMap[winners[movie]]) {
					data.voteMap[winners[movie]].forEach((val: any) => {
						allData.push(val)
					})
				}
			}
			//console.log('data', allData)
			setWinningVotes(allData)
			return allData
		}
		setWinningVotes(allData)
		setFetch(false)
	}

	const renderContent = (text: string) => {
		if (!text) return
		const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([a-zA-Z0-9_-]+)/gi
		const matches = text.match(regex)
		if (matches) {
			return matches.map((url, index) => <ReactPlayer url={url} controls width="100%" key={index} />)
		}
		return null
	}

	const onClose = () => {
		setOpen(false)
	}

	React.useEffect(() => {
		findWinner()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	return (
		<DscModal open={open} onClose={onClose} onCancel={onClose} title="Winners" cancelLabel="Cancel" size="large">
			{!fetch && winnersMovie && winnersMovie.length ? (
				<div className="flex flex-col gap-4  overflow-hidden relative min-w-[700px] modal-body">
					<div className="max-h-[400px] modal-body overflow-y-auto">
						<h1 className="font-bold text-lg mb-2">Movie</h1>
						{winnersMovie && winnersMovie.length === 1 ? (
							winnersMovie.map((val: string, key: number) => (
								<div className="show-preview-movie " key={key}>
									{renderContent(val)}
								</div>
							))
						) : (
							<div className="flex items-center flex-col">
								{/**
                   * <div className="flex items-center gap-10">
									<button>
										<Tooltip title="Back" placement="top">
											<span className="material-symbols-outlined cursor-pointer">chevron_left</span>
										</Tooltip>
									</button>
									<button>
										<Tooltip title="Next" placement="top">
											<span className="material-symbols-outlined cursor-pointer">chevron_right</span>
										</Tooltip>
									</button>
								</div>
                   */}
								<div className="flex items-center overflow-hidden">
									{winnersMovie.map((val: string, key: number) => (
										<div className="show-preview-movie " key={key}>
											{renderContent(val)}
										</div>
									))}
								</div>
							</div>
						)}

						{winningVotes && winningVotes.length ? (
							winningVotes?.map((value: Winner, key: number) => (
								<div
									key={key}
									className="mt-2 flex items-center gap-[8px] hover:bg-gray-100 p-4 cursor-pointer justify-between">
									<div className="flex flex-col gap-2 w-full">
										<div className="w-full flex items-center justify-between">
											<h1 className="mb-2 font-bold text-lg">Votes</h1>
											<h1 className="mb-2 font-bold text-lg">Amount</h1>
										</div>
										<div className="flex items-center justify-between w-full">
											<div className="flex items-center gap-4">
												<span className="material-symbols-outlined">person</span>
												<TextH3 sx={colorVariant}>{value.user}</TextH3>
											</div>
											<span className="font-bold text-bold text-black">
												{planksToTokens(value.amount) || ''} KinexPlay{' '}
											</span>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="w-[700px]">
								<p>No data to display</p>
							</div>
						)}
					</div>
				</div>
			) : (
				<Loading />
			)}
		</DscModal>
	)
}

export default WinnersModal
