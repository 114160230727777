import styled from 'styled-components'

interface DsAccordionContainerProps {
	isOpen: boolean
}

export const DsAccordionContainer = styled.div`
	width: 100%;
	border: solid 1px #edeff1;
	padding: 16px;
`

export const DsAccordionInternal = styled.div`
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`

export const DsAccordionTitle = styled.p<{ isOpen: boolean }>`
	font-weight: 700;
	margin-block: 0px !important;
	font-family: var(--font-circular-std);
	font-size: 18px;
	line-height: 24px;
	color: ${({ isOpen }) => (isOpen ? 'var(--primary)' : 'inerith')};
`

export const DscAccordionHover = styled.div`
	&:hover {
		background: var(--surface-primary);
		border-radius: 100%;
	}
`

export const DsAccordionContent = styled.div`
	margin-top: 15px;
`

export const SmallTitle = styled.h3`
	color: inherit;
	margin-block: 0px;
	font-family: 'Circular Std';
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
`
