export default {
	CONSTELATIONS: 'Constellations',
	RANKINGS: 'Rankings',
	CURRENT: 'Actuelle',
	CREATE_PROFILE: 'Create Social profile',
	PUBLIC_PROFILE: 'Public profile',
	PROFILE_SETTINGS: 'Profile settings',
	SOCIAL_PAGE: 'Social network',
	FOLLOWERS: 'Following',
	FOLLOWING: 'Following',
	MY_CONSTELLATIONS: 'My Constellations',
	VOTED_RANKINGS: 'Voted Rankings',
	VOTED_CONSTELLATIONS: 'Voted Constellations',
	REWARD_KINEX: 'Claim Tokens',
	MODERATION: 'Moderation',
	HELP: 'Help',
	CREATE_FESTIVAL_TEXT: 'The Power of Curatorial Competitions',
	FESTIVAL_DETAILS_TEXT: 'Help us to discover the best content.',
	CREATE_CONSTELLATION: 'Create Constellation',
	CREATE_SOCIAL_PROFILE_TO_CREATE_CONSTELLATION: 'Create a social profile to create new constellations',
	WALLET_STATUS: 'Wallet status',
	NAME: 'Name',
	SOURCE: 'Source',
	REPUTATION: 'Reputation',
	LOCK_FESTIVAL: 'Tokens lock constellations',
	LOCK_RANKING: 'Locked tokens in rankings',
	PRODUCT_TOOLS: 'Produtions Tools',
}
