import React, { useState, useContext, useEffect } from 'react'
import KineContext from '../../context/KineContext'
import { FestivalService } from '../../service/festival/festivalService'
import { NewCategorieRecursive } from './components/Categorie'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Loading } from '../../components/loading'
import Switch from '@mui/material/Switch'
import ActiveInstaConstelation from './components/activeInstaConstelation'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import Button from 'src/components/_Dsc/Button'
import InputText from 'src/components/_Dsc/InputText'
import { TextStyleH1 } from 'src/components/_Dsc/typography'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'

const CreateConstelation: React.FC = () => {
	const { user, color, apiState, setGlobalToast, setGlobalSnack, profile } = useContext(KineContext)
	const [title, setTitle] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [maxEntry, setMaxEntry] = useState<any>(null)
	const [useMaxEntry, setUseMaxEntry] = useState(false)
	const [currentCategories, setCurrentCategories] = useState<any[]>([])
	const [tags, setTags] = useState<any[]>([])
	const [selectedCategory, setSelectedCategory] = useState<any>('')
	const [disabled, setDisabledState] = useState<boolean>(true)
	const [maxDescription, setMaxDescription] = useState<boolean>(false)
	const [fetch, setFetch] = useState<boolean>(false)
	const [links, setLinks] = useState<string[]>([])
	const [linkInput, setLinkInput] = useState<string>('')
	const [linkPreviews, setLinkPreviews] = useState<{
		[key: string]: { thumbnailUrl: string; title: string }
	}>({})
	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'Create Constellations', link: '' },
	]
	const [open, setOpen] = useState(false)
	const navigate = useNavigate()

	let id = 1

	const formatedDetailsCategories: any = {
		Artifitial_Intelligence: { description: 'AI-assisted Videos or Films' },
		Cinema: { description: 'Fiction, Documentary, ...' },
		Crypto: { description: 'Degen, Podcast, Traders, Others' },
		Gaming_Streamers: { description: 'Games and Gamers' },
		Just_Fun: { description: 'Comic Videos' },
		We_Festivals: { description: 'Experimental Genres, Music Videos, ...' },
	}

	const validateLink = (link: string) => {
		if (!link.includes('watch')) {
			return false
		}
		const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
		getDisabledState()
		return youtubePattern.test(link)
	}

	const addLink = () => {
		if (!validateLink(linkInput)) {
			alert('Please enter a valid YouTube link.')
			return false
		}

		if (links.includes(linkInput)) {
			Swal.fire({
				icon: 'info',
				title: 'Oops...',
				text: 'This video is already present in the constellation',
				footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
				willClose: () => {
					//window.location.reload();
				},
			})
			setLinkInput('')
			return false
		} else {
			setLinks([...links, linkInput])
			fetchLinkPreview(linkInput)
			setLinkInput('')
			getDisabledState()
		}
	}

	const removeLink = (index: number) => {
		setLinks(links.filter((_, i: any) => i !== index))
		const updatedPreviews = { ...linkPreviews }
		delete updatedPreviews[links[index]]
		setLinkPreviews(updatedPreviews)
		getDisabledState()
	}
	const fetchLinkPreview = async (link: string) => {
		console.log('maxDescription', maxDescription)
		let apiUrl = ''
		if (link.includes('youtube.com') || link.includes('youtu.be')) {
			apiUrl = `https://www.youtube.com/oembed?url=${link}&format=json`
		} else if (link.includes('tiktok.com')) {
			apiUrl = `https://www.tiktok.com/oembed?url=${link}`
		}

		if (apiUrl) {
			const headers = {
				'x-cors-api-key': process.env.REACT_APP_KEY_PROXY,
			}
			const proxyUrl = 'https://cors.bridged.cc/'
			try {
				const response = await axios.get(proxyUrl + apiUrl, { headers })
				const data = await response.data
				setLinkPreviews((prev: any) => ({
					...prev,
					[link]: { thumbnailUrl: data.thumbnail_url, title: data.title },
				}))
			} catch (error) {
				console.error('Error fetching preview:', error)
			}
		}
	}

	const createFestival = async () => {
		// Your existing createFestival logic
		if (links.some((link: any) => !validateLink(link))) {
			alert('One or more links are invalid. Please remove them to proceed.')
			setFetch(false)
			return
		}

		setFetch(true)
		let accCategories: any = []
		currentCategories.forEach((val: any) => {
			accCategories.push({ category: val.key, subCategory: val.param })
		})

		// Assume FestivalService.createFestival now also takes links as a parameter
		let req = await FestivalService.createFestival(
			title,
			description,
			useMaxEntry ? maxEntry : 0,
			0,
			user,
			'm',
			accCategories,
			apiState,
			links.join(','),
			setOpen,
			setGlobalToast,
		)
		if (req && links.length < 2) {
			setGlobalSnack(true)
			let id = await FestivalService.getLastedCreatedFestival(user, apiState)
			if (id) {
				navigate('/details/' + id)
			} else {
				navigate('/my-constellations')
			}
		}

		setFetch(false)
		setGlobalToast(false)
	}

	/**
   * const addNewCategorie = () => {
		const newCategory = { key: selectedCategory.param, param: '', id: id++ }
		setCurrentCategories([...currentCategories, newCategory])
		getDisabledState()
	}
   */

	const getAllTags = async () => {
		if (apiState) {
			const request = await FestivalService.getAllTags(apiState)

			if (request && request.length) {
				request.forEach((val: any) => {
					let currentLabel = val.param.includes(' ') ? val.param.replace(' ', '_') : val.param
					currentLabel = currentLabel.includes('/') ? currentLabel.replace('/', '_') : currentLabel
					if (formatedDetailsCategories && formatedDetailsCategories[currentLabel]) {
						val.description = formatedDetailsCategories[currentLabel]
					}
				})
				setTags(request)
				const newCategory = { key: selectedCategory.param, param: '', id: id++ }
				setCurrentCategories([...currentCategories, newCategory])
			}
		}
	}

	const handleChangeCategory = (event: SelectChangeEvent) => {
		const value = event.target.value as any // Assuming your value is an object
		setSelectedCategory(value)
		setCurrentCategories([{ key: value.param, param: '', id: id++ }])
		getDisabledState()
	}

	const getDisabledState = () => {
		const verifyCategories = currentCategories.filter((val: any) => !val.param || !val.key)

		const formated = title && description && description.length > 0 && !verifyCategories.length
		setDisabledState(!formated)
	}

	const handleInputChange = (event: any) => {
		const input = event.target.value
		const formattedInput = input.replace(/[^0-9.,]|(?<=(\.|,)).|[.,](?=[.,])/g, '')
		setMaxEntry(formattedInput)
	}

	const changeMaxEntry = (state: any) => {
		setUseMaxEntry(!useMaxEntry)
		if (!state.target.checked) {
			setMaxEntry('')
		}
	}

	const handleChange = (e: any, set: any, param?: boolean) => {
		if (param) {
			if (e.length < 500) {
				set(e)
				setMaxDescription(false)
				getDisabledState()
				return true
			} else {
				const trimmedDescription = e.substring(0, 500)
				set(trimmedDescription)
				setMaxDescription(true)
				getDisabledState()
				return true
			}
		} else {
			set(e)
			getDisabledState()
		}
	}

	const changeInputLink = (e: string) => {
		setLinkInput(e)
	}

	useEffect(() => {
		if (apiState) {
			getAllTags()

			if (!profile) {
				navigate('/')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState, profile])

	useEffect(() => {
		if (links) {
			links.forEach((link: any) => {
				if (!linkPreviews[link]) {
					fetchLinkPreview(link)
				}
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [links])

	return (
		<div className={`page-padding create-constelation`}>
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer>
					{open && links && links.length >= 2 && (
						<ActiveInstaConstelation open={open} setOpen={setOpen} description={description} />
					)}
					<div className="">
						<TextStyleH1
							className={`text-3xl font-extrabold leading-9 tracking-tight sm:text-4xl sm:leading-10 md:text-6xl md:leading-14 `}>
							Create Constellation
						</TextStyleH1>
						<span className="font-normal">Constellations are film or video competitions</span>
					</div>
					{user && user.address ? (
						<div className="p-4 rounded-[4px] mt-4 ">
							<div className="  min-w-[500px] w-full flex flex-col gap-2">
								<div
									className={
										currentCategories && currentCategories.length > 0 && !selectedCategory
											? `cat-father  p-4 items-center default-shadow pulse-animation`
											: 'cat-father  p-4 items-center default-shadow'
									}>
									{currentCategories && currentCategories.length > 0 && (
										<Box sx={{ minWidth: 120 }}>
											<FormControl variant="standard" fullWidth>
												<InputLabel id="category-select-label" sx={{ color: '#000' }}>
													Category
												</InputLabel>
												<Select
													sx={{ color: '#000' }}
													labelId="category-select-label"
													id="category-select"
													value={selectedCategory}
													onChange={handleChangeCategory}>
													{tags &&
														tags.length > 0 &&
														tags.map(
															(category: any) =>
																!category.param.includes('All') && (
																	<MenuItem key={category.param} value={category}>
																		{category.param}: [
																		{category && category.description && category.description.description
																			? category.description.description
																			: ''}
																		]
																	</MenuItem>
																),
														)}
												</Select>
											</FormControl>
										</Box>
									)}
									{currentCategories.map((category: any, index: number) => (
										<NewCategorieRecursive
											key={category.id} // Unique key for each category
											selectedCategory={selectedCategory}
											getDisabledState={getDisabledState}
											tags={tags}
											val={currentCategories[index]}
										/>
									))}
								</div>
								<div className="flex flex-col gap-2 relative">
									<label className={`block font-bold  text-[${color.text}]`} htmlFor="description">
										Description
									</label>
									<textarea
										className="border rounded py-2 px-2 text-grey-darkest w-full"
										onChange={event => handleChange(event.target.value, setDescription, true)}
										name="description"
										id="description"
										value={description}
										placeholder="Description..."
										disabled={!selectedCategory}
									/>
									<p className="text-gray-700 -mt-[5px] w-full justify-end flex absolute bottom-[5px] right-[15px]">
										{description.length}/500
									</p>
								</div>
								<div className="flex flex-col  justify-between gap-2">
									<label className={`block font-bold text-[${color.text}]`} htmlFor="festivalTitle">
										Question
									</label>
									<InputText value={title} onChange={setTitle} placeholder="Question..." disabled={!selectedCategory} />
								</div>
								<div className="flex flex-col  justify-between gap-2">
									<label className={`block font-bold text-[${color.text}]`} htmlFor="maxEntry">
										Maximum Number of Tokens Used to Vote
									</label>

									<input
										className="border rounded py-2 px-3 text-grey-darkest w-full"
										onChange={(event: any) => {
											handleInputChange(event)
										}}
										type="text"
										value={maxEntry}
										pattern="[0-9.,]*"
										name="maxEntry"
										id="maxEntry"
										placeholder="Max entry token..."
										disabled={!useMaxEntry}
									/>

									<div className="absolute top-[34px] right-0">
										<Switch
											checked={useMaxEntry}
											onChange={changeMaxEntry}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									</div>
								</div>
								<div className="flex flex-col gap-2">
									<label className={`block font-bold  text-[${color.text}]`}>Movie Link YouTube</label>
									<div className="flex gap-2  items-center">
										<InputText
											type="text"
											placeholder="Add YouTube  link here..."
											value={linkInput}
											onChange={changeInputLink}
											disabled={!selectedCategory}
										/>
										<Button onClick={addLink} value="Insert" size="small"></Button>
									</div>
									<ul>
										{links.map((link, index) => (
											<li key={index} className="flex justify-between items-center ">
												<div className="flex items-center gap-5">
													{linkPreviews[link] && (
														<img
															src={linkPreviews[link].thumbnailUrl}
															alt="Preview"
															style={{ width: 80, height: 50 }}
														/>
													)}
													<a
														href={link}
														target="_blank"
														rel="noopener noreferrer"
														className={`hover:underline text-[${color.text}]`}>
														{linkPreviews[link] ? linkPreviews[link].title : link}
													</a>
												</div>
												<Button value="Remove" onClick={() => removeLink(index)} size="small"></Button>
											</li>
										))}
									</ul>
								</div>
								<div className="flex items-center justify-end gap-2">
									{!fetch ? (
										<Button value="Create" onClick={createFestival} disabled={disabled} size="small"></Button>
									) : (
										<Loading />
									)}
								</div>
							</div>
						</div>
					) : (
						<p className={`text-[${color.text}] p-2 bg-[${color.info}] default-shadow`}>
							You need to have your wallet connected
						</p>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default CreateConstelation
