import React, { useEffect, useState } from "react";
import InitialSimbol from "src/components/_Dsc/InitialSimbol";
import { TextStyleBodyDefault } from "src/components/_Dsc/typography";
import { Loading } from "src/components/loading";
import { communityService } from "src/service/community/communityService";

interface Member  {
    communityId: string
    communityName: string
    user: string
    socialName: string
}

const Members: React.FC = () => {
    const [members, setMembers] = useState([] as Member[])
    const [inFetch, setInFetch] = useState(true)
    const getCurrentMembers = async () => {
        setInFetch(true)
        let req = await communityService.getAllMembersInCommunity(window.location.pathname.split('/')[2])
        setMembers(req)
        setInFetch(false)
    }
    useEffect(() => {
        getCurrentMembers()
    },[])
    return (
    
          <div>
             {
            inFetch
            ? <Loading />
            : <div className="flex flex-col gap-1 ">
                {
                    members && members.length
                    ? members.map((member: Member, index: number)=> (
                        <div key={index} className="bg-white rounded-[4px] p-4 flex items-center gap-2">
                            <InitialSimbol profile={{img: undefined, name: member.socialName}}/>
                            <TextStyleBodyDefault>{member.socialName}</TextStyleBodyDefault>
                        </div>
                    ))
                    : 'No data to display'
                }
            </div>
           }
          </div>
      
    )
}
export default Members