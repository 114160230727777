import React from 'react'
import './styles.scss'
import { TextStyleBodyDefault } from 'src/components/_Dsc/typography'
import Button from 'src/components/_Dsc/Button'
import { useNavigate } from 'react-router-dom'

interface Props {
	data: any
}

const PopularProfile: React.FC<Props> = ({ data }) => {
	const navigate = useNavigate()

	const handleClick = (address: string) => {
		navigate('/social/view/' + address)
	}

	return (
		<div className="popular-profile-card">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-4">
					<img
						src={
							data.img ||
							'https://isobarscience-1bfd8.kxcdn.com/wp-content/uploads/2020/09/default-profile-picture1.jpg'
						}
						alt="selected"
						className="rounded-full"
						style={{ height: '50px', width: '50px' }}
					/>
					<div>
						<TextStyleBodyDefault>
							{data.name} {data.tag ? `- @${data.tag}` : ''}
						</TextStyleBodyDefault>
						<p className="text-[12px] overflow-hidden truncate max-w-[150px]">{data.description}</p>
					</div>
				</div>

				<div>
					<p className="text-sm">{data.followers || 0}</p>
					<Button
						type="secondary"
						value="View"
						size="small"
						onClick={() => {
							handleClick(data.user)
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default PopularProfile
