import React, { useState } from 'react'
import DscModal from 'src/components/_Dsc/Modal'
import './style.scss'
interface Props {
	urls: any
}

const ImagesDisplay: React.FC<Props> = ({ urls }) => {
	const [open, setOpen] = useState(false)
	const [currentImg, setCurrentImg] = useState('')

	const openModal = (url: string) => {
		setOpen(true)
		setCurrentImg(url)
	}

	const closeModal = () => {
		setOpen(false)
	}

	return (
		<div className="images-view">
			{open && (
				<DscModal open={open} cancelLabel="Close" onClose={closeModal} onCancel={closeModal} title="Image">
					<div className="flex m-auto w-full justify-center flex items-center overflow-hidden relative">
						<img src={currentImg} alt="logo" className="image-block-style" />
						{/**
             * 	<div className="absolute flex absolute-container-images bg-red gap-4 p-2">
							{urls.map(
								(val: string, i: number) =>
									val !== currentImg && (
										<img
											onClick={() => {
												handleChange(val)
											}}
											src={val}
											alt="image"
											className="max-w-[100px] max-h-[100px] image-block-style hover:opacity-70 cursor-pointer"
										/>
									),
							)}
						</div>
             */}
					</div>
				</DscModal>
			)}
			<div className="flex flex-col w-full h-full rounded-lg p-4 cursor-pointer ">
				<div className="flex w-fit rounded-[50px] overflow-hidden">
					<img
						src={urls[0]}
						alt="logo"
						className="max-w-[350px] max-h-[400px]"
						onClick={() => {
							openModal(urls[0])
						}}
					/>
					<div className="w-full flex flex-col">
						{urls.map(
							(val: string, i: number) =>
								i > 0 &&
								i < 3 && (
									<img
										onClick={() => {
											openModal(val)
										}}
										src={val}
										alt="logo"
										className="max-w-[200px] max-h-[400px] image-block-style hover:opacity-90"
									/>
								),
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImagesDisplay
