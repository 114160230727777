import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'

import './styles.scss'
import KineContext from 'src/context/KineContext'
import { useNavigate } from 'react-router-dom'

import { getUserSavePosts } from 'src/service/social/socialService'
import { Loading } from 'src/components/loading'
import Feed from '../social/components/feed/Feed'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Favorites', link: '' },
]

const UserFavorites: React.FC = () => {
	const [data, setData] = useState<any>([])
	const [inFetch, setInFetch] = useState<boolean>(false)
	const { user, profile } = React.useContext(KineContext)
	const navigate = useNavigate()

	const getUsersPosts = async () => {
		setInFetch(true)
		let req = await getUserSavePosts(user.address)
		if (req && req.data) {
			setData(req.data)
		}
		setInFetch(false)
	}
	useEffect(() => {
		if (!user) {
			navigate('/')
		}
		if (user && user.address) {
			getUsersPosts()
		}
	}, [user])

	return (
		<div className="max-app page-padding pb-max documents">
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer>
					{inFetch ? (
						<Loading />
					) : (
						<div>
							{data && data.length ? (
								<Feed posts={data} profile={profile} />
							) : (
								<TextStyleBodyDefaultMedium>Empty data</TextStyleBodyDefaultMedium>
							)}
						</div>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default UserFavorites
