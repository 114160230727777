import React, { useState } from "react";
import Tabs from "src/components/_Dsc/Tabs";
import { TextStyleBodyDefaultMedium } from "src/components/_Dsc/typography";
import { Models } from "./components/Models";
import { Prompts } from "./components/Prompts";
import { Rags } from "./components/Rags";
import { Characters } from "./components/Characters";

const options = [
    { label: 'Models', value: 'Models' },
    { label: 'Prompts', value: 'Prompts' },
    { label: 'Rags', value: 'Knowledge' },
    { label: 'Characters', value: 'characters' }
]

export const GPT: React.FC = () => {
    const [isSelected, setIsSelected] = useState('Models')
    
    const handleTabsChange = (val: string) => {
        setIsSelected(val)                      
    }

    return (
        <div className="flex flex-col">
            <TextStyleBodyDefaultMedium>GPT's</TextStyleBodyDefaultMedium>
            <div>
                <Tabs options={options} value={isSelected} onChange={handleTabsChange} />
            </div>
            {
                isSelected === 'Models'
                ? <Models />
                : isSelected === 'Prompts'
                ? <Prompts />
                : isSelected === 'Knowledge'
                ? <Rags />
                : isSelected === 'characters'
                ? <Characters />
                : ''
            }
        </div>
    )
}
