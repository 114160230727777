export default {
	CONSTELATIONS: 'Constellations',
	RANKINGS: 'Classements',
	CURRENT: 'Current',
	CREATE_PROFILE: 'Créer un profil social',
	PUBLIC_PROFILE: 'Profil public',
	PROFILE_SETTINGS: 'Paramètres du profil',
	SOCIAL_PAGE: 'Page sociale',
	FOLLOWERS: 'Abonnés',
	FOLLOWING: 'Suivante',
	MY_CONSTELLATIONS: 'Mes constellations',
	VOTED_RANKINGS: 'Classements votés',
	VOTED_CONSTELLATIONS: 'Constellations votées',
	REWARD_KINEX: 'Récompense KinexPlay ',
	MODERATION: 'Modération',
	HELP: 'Aide',
	CREATE_FESTIVAL_TEXT: 'Créez des festivals et générez de nouvelles interactions',
	FESTIVAL_DETAILS_TEXT: 'Créez, votez, republiez et assurez de nouvelles interactions.',
	CREATE_CONSTELLATION: 'Créer une constellation',
	CREATE_SOCIAL_PROFILE_TO_CREATE_CONSTELLATION: 'Créez un profil social pour créer de nouvelles constellations',
	WALLET_STATUS: 'Statut du portefeuille',
	NAME: 'Nom',
	SOURCE: 'Source',
	REPUTATION: 'Réputation',
	LOCK_FESTIVAL: 'Les jetons verrouillent la constellation',
	LOCK_RANKING: 'Jetons verrouillés dans les classements',
}
