import React from 'react'

import { Tab, Tabs } from '@mui/material'
import Followers from '../followers'
import Followings from '../followings'

import '../style.scss'
import Modal from 'src/components/_Dsc/Modal'
import '../style.scss'

interface Props {
	open: boolean
	setOpen: (value: boolean) => void
	targetTab: string
	isTarget: boolean
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	height: 'auto',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
}

const ModalFollowersAndFollowing: React.FC<Props> = ({ isTarget, open, setOpen, targetTab }) => {
	const [tab, setTab] = React.useState<string>('followers')

	const handleClose = () => {
		setOpen(!open)
	}

	const handleTabChange = (event: React.SyntheticEvent, newUploadType: string) => {
		setTab(newUploadType)
	}

	React.useEffect(() => {
		if (isTarget || targetTab) {
			setTab(targetTab)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Modal
			title="Social"
			open={open}
			onClose={handleClose}
			type="primary"
			size="large"
			confirmLabel="Close"
			onConfirm={handleClose}
		>
			<div className="flex flex-col justify-between ">
				<Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
					<Tab label="Followers" value={'followers'} />
					<Tab label="Following" value={'following'} />
				</Tabs>
				<div className="mt-2 mb-2 overflow-items">
					{tab === 'followers' ? (
						<Followers setOpen={setOpen} isTarget={isTarget} />
					) : (
						<Followings setOpen={setOpen} isTarget={isTarget} targetTab={targetTab} />
					)}
				</div>
			</div>
		</Modal>
	)
}

export default ModalFollowersAndFollowing
