import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1, TextStyleH3 } from 'src/components/_Dsc/typography'
import { Community } from '../communities'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'
import DscIcons from 'src/components/_Dsc/icons'

import { useNavigate } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Communities', link: '/communities' },
	{ label: 'My Communities', link: '' },
]

const UserCommunities: React.FC = () => {
	const { user } = React.useContext(KineContext)
	const navigate = useNavigate()
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [communitites, setCommunities] = useState<Community[]>([])

	const getUserCommunities = async () => {
		setInFetch(true)
		let req = await communityService.getCommunitiesByUser(user.address)
		setCommunities(req)
		setInFetch(false)
	}

	const gotToCommunity = (community: Community) => {
		if (community) {
			navigate(`/community/${community.id}`)
		}
	}

	useEffect(() => {
		if (user && user.address) {
			getUserCommunities()
		}
	}, [user])

	return (
		<div className="page-padding">
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex items-center justify-between">
							<TextStyleH1>My Communities</TextStyleH1>
						</div>
					</div>
					<div className="flex flex-col gap-2 w-full rounded-[4px] mt-4">
						{inFetch ? (
							<div className="p-4">
								<Loading />
							</div>
						) : (
							<div className="flex flex-col gap-2">
								{communitites && communitites.length ? (
									communitites.map((val: Community, index: number) => (
										<div
											key={index}
											className="bg-white p-4 flex flex-col w-full items-end justify-end gap-2 rounded-[4px]">
											<div className=" flex  w-full justify-between">
												<div className="flex items-center gap-2">
													<img
														src={val.icon}
														width="60px"
														height="60px"
														className="rounded-full"
														alt="logo community"
													/>
													<div className="flex flex-col gap-1">
														<TextStyleH3>{val.name}</TextStyleH3>
														<TextStyleBodyDefault>{val.description}</TextStyleBodyDefault>
													</div>
												</div>
												<div className="flex flex-col items-end">
													<TextStyleCaption>{Object.keys(val.communityType)[0]}</TextStyleCaption>
													<div className="flex items-center gap-1">
														<TextStyleBodyDefault>{val.members}</TextStyleBodyDefault>
														<DscIcons icon="group" />
													</div>
												</div>
											</div>
											{user && user.address ? (
												<Button
													value="View"
													size="small"
													onClick={() => {
														gotToCommunity(val)
													}}
												/>
											) : (
												''
											)}
										</div>
									))
								) : (
									<p>No data to display</p>
								)}
							</div>
						)}
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default UserCommunities
