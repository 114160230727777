import React, { useState, useEffect, useCallback } from 'react'
import { FestivalService } from '../../../service/festival/festivalService'
import Card from '../../../components/card/Card'
import { Skeleton } from '@mui/material'
import KineContext from '../../../context/KineContext'
import { TextBold } from 'src/components/_Dsc/typography/TextLg'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'

const defaultVal = [1, 2, 3]

interface Props {
	user?: string
	keyParam: string
	address?: any
}

const MyFestivals: React.FC<Props> = ({ user, keyParam }) => {
	const { color, apiState } = React.useContext(KineContext)
	const [ownerData, setOwnerData] = useState<any>([])
	const [fetch, setFetch] = useState<boolean>(false)

	const getUserData = useCallback(async () => {
		setFetch(true)

		let req = await FestivalService.getUserWinAndOwnerFestivals(
			user ? user : window.location.pathname.split('/')[3],
			keyParam ? keyParam : 'allOwnedFestivals',
			apiState,
		)
		setOwnerData(req)
		setFetch(false)
	}, [apiState, keyParam, user])

	/**
   * const verifyUpdate = useCallback(async () => {
		let req = await FestivalService.getUserWinAndOwnerFestivals(
			user ? user : window.location.pathname.split('/')[3],
			keyParam ? keyParam : 'allOwnedFestivals',
			apiState,
		)

		setOwnerData(req)
	}, [apiState, user, keyParam])
   */

	useEffect(() => {
		if (apiState) {
			getUserData()
			const interval = setInterval(() => {
				//	verifyUpdate()
			}, 2000)

			return () => clearInterval(interval)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])
	return (
		<div className="mt-1 w-full items-center">
			{ownerData && ownerData?.length > 0 && !fetch && (
				<div className="flex flex-wrap items-center gap-10 cards-content">
					{ownerData?.map((value: any, number: number) => (
						<div key={number} className={`bg-[${color.card}] w-[300px] grow cards default-shadow`}>
							<Card data={value} />
						</div>
					))}
				</div>
			)}

			{ownerData && ownerData?.length <= 0 && !fetch && <TextBold size="20" text="No data to display" />}

			{!ownerData && !fetch && <TextStyleBodyDefaultMedium>No data to display at this time</TextStyleBodyDefaultMedium>}
			<>
				{fetch && (
					<div className="flex items-center gap-10 p-4 flex-wrap wrap justify-center w-full h-full">
						{defaultVal?.map((value: any, i: number) => (
							<div key={i} className="w-[300px]">
								<Skeleton variant="rectangular" width={'100%'} height={200} />
							</div>
						))}
					</div>
				)}
			</>
		</div>
	)
}

export default MyFestivals
