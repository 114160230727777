import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import Modal from 'src/components/_Dsc/Modal'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import { SuccessToast } from 'src/components/Toast'
import { ErrorToast } from 'src/components/Toast/Error'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { CreateDocument } from './components/CreateDocument'
import { UploadFile } from './components/UploadFile'
import { GPT } from './components/GPT'

interface Props {
	community_id: string
}

const CommunityActions: React.FC<Props> = ({ community_id }) => {
	const { user } = React.useContext(KineContext)

	const [showModalUpload, setShowModalUpload] = useState<boolean>(false)
	const [successBuy, setSuccessBuy] = useState(false)
	const [isError, setIsError] = useState(false)
	const [showModal, setShowModal] = useState<boolean>(false)

	const [type, setType] = useState('')

	const onOpen = () => {
		setShowModal(true)
	}

	return (
		<div className="flex flex-col w-full bg-white p-4 rounded-[4px] gap-2">
			<GPT />
		</div>
	)
}

export default CommunityActions
