import React, { useState } from 'react'

import Close from '../../../../assets/close.svg'

import { FestivalService } from '../../../../service/festival/festivalService'
import KineContext from '../../../../context/KineContext'

import DscModal from 'src/components/_Dsc/Modal'
import TextH3 from 'src/components/_Dsc/typography/TextH3'
import DscInput from 'src/components/_Dsc/input'

interface Props {
	open: boolean
	setOpen: any
	id: string
	data: any
	action: any
}

const EditConstellationData: React.FC<Props> = ({ open, setOpen, id, data, action }) => {
	const { user, apiState } = React.useContext(KineContext)

	/**
	 * const [errorMessage, setErrorMessage] = useState<String>('')
	 */
	const [title, setTitle] = useState<string>('')
	const [editDescription, setEditDescription] = useState<string>('')

	const colorVariantTitle = {
		color: '#000',
		fontSize: '25px',
		fontWeight: 'bold',
	}
	const sendAction = async () => {
		await FestivalService.editFestivalData(id, title, editDescription, user, apiState, action)
		setOpen(false)
	}

	const handleChange = (e: any) => {
		setTitle(e)
	}
	const handleChangeDescription = (e: any) => {
		setEditDescription(e)
	}

	const onCancel = () => {
		setOpen(false)
	}

	React.useEffect(() => {
		if (data) {
			setTitle(data.name)
			setEditDescription(data.description)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	return (
		<DscModal
			open={open}
			onCancel={onCancel}
			onClose={onCancel}
			title="Edit constellation data"
			cancelLabel="cancel"
			confirmLabel={'Save'}
			onConfirm={sendAction}
		>
			<div className="flex flex-col gap-2">
				<div className="p-2">
					<DscInput type="text" value={title} label="Question:" placeholder={title} onChange={handleChange} />
				</div>
				<div className="p-2">
					<DscInput
						type="text"
						value={editDescription}
						label="Description:"
						placeholder={editDescription}
						onChange={handleChangeDescription}
					/>
				</div>
			</div>
		</DscModal>
	)
}

export default EditConstellationData
