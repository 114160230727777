import React from 'react'
import { ModerationService } from '../../../../service/moderation/moderationService'

import { useParams } from 'react-router-dom'
import { Loading } from '../../../../components/loading'
import KineContext from '../../../../context/KineContext'

const Veredicts: React.FC = () => {
	const [currentData, setCurrentData] = React.useState([] as any)
	const [fetch, setFetch] = React.useState<boolean>(true)
	const { apiState } = React.useContext(KineContext)

	const { id, extraParam } = useParams()

	const getCurrentWindowData = async () => {
		setFetch(true)
		let req = await ModerationService.getReportByContent(id, extraParam, apiState)
		setCurrentData(req)

		setFetch(false)
	}

	React.useEffect(() => {
		getCurrentWindowData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div>
			{fetch ? (
				<Loading />
			) : (
				<section className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
					<div className="flex h-screen flex-col justify-between font-sans">
						<main className="mb-auto">
							<section className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
								<article>
									<div className="xl:divide-y xl:divide-gray-200 xl:dark:divide-gray-700">
										<header className="pt-6 xl:pb-6">
											<div className="space-y-1 text-center">
												<dl className="space-y-10">
													<div>
														<dt className="sr-only">Published on</dt>
														<dd className="text-base font-medium leading-6 text-gray-500 dark:text-gray-400">
															<time dateTime="2023-08-05T00:00:00.000Z">
																{extraParam}, {id}
															</time>
														</dd>
													</div>
												</dl>
												<div>
													<h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
														{currentData.status === 'InResolution' ? 'In Resolution' : ''}
													</h1>
												</div>
											</div>
										</header>
										<div className="grid-rows-[auto_1fr] divide-y divide-gray-200 pb-8 dark:divide-gray-700 xl:grid xl:grid-cols-4 xl:gap-x-6 xl:divide-y-0">
											<dl className="pb-10 pt-6 xl:border-b xl:border-gray-200 xl:pt-11 xl:dark:border-gray-700">
												<dt className="sr-only">Authors</dt>
												<dd>
													<ul className="flex flex-wrap justify-center gap-4 sm:space-x-12 xl:block xl:space-x-0 xl:space-y-8">
														<li className="flex items-center space-x-2 flex-col">
															<p>Report by:</p>
															<p className="truncate">{currentData.reporterId}</p>
														</li>
													</ul>
												</dd>
											</dl>
											<div className="divide-y divide-gray-200 dark:divide-gray-700 xl:col-span-3 xl:row-span-2 xl:pb-0">
												<div className="prose max-w-none pb-8 pt-10 dark:prose-invert">
													<h2 id="introduction">
														<p className="text-lg leading-7 text-gray-500">Content:</p>
													</h2>
													<p className="mt-4 text-lg leading-7 text-gray-500">{currentData.justification}</p>
												</div>
											</div>
										</div>
										<div className="flex items-center justify-end w-full gap-10 pt-4">
											<button className="secondary-blue flex items-center p-2 text-white  rounded-[4px]">
												I agree
											</button>
											<button className="cancel-color p-2 text-white  flex items-center rounded-[4px]">
												I disagree
											</button>
										</div>
									</div>
								</article>
							</section>
						</main>
					</div>
				</section>
			)}
		</div>
	)
}

export default Veredicts
