import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import InputText from 'src/components/_Dsc/InputText'
import Modal from 'src/components/_Dsc/Modal'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
	TextStyleH2,
} from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'

export interface Prompt {
	id: string
	community_id: number
	name: string
	content: string
	command: string
	owner_id: string
	owner_name: string
	created_at: string
	updated_at: string
}

export const Prompts: React.FC = () => {
	const { user, handleGlobalMessage, setOnQueue } = React.useContext(KineContext)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [isEdit, setIsEdit] = useState<boolean>(false)
	const [title, setTitle] = useState<string>('')
	const [content, setContent] = useState<string>('')
	const [command, setCommand] = useState<string>('')
	const [prompts, setPrompts] = useState<Prompt[]>([])
	const [currentPrompt, setCurrentPrompt] = useState<Prompt>({} as Prompt)
	const { id } = useParams()

	const getAllPrompts = async () => {
		if (id) {
			setInFetch(true)
			let req = await communityService.getCommunitiesPrompts(id)
			setPrompts(req)
			setInFetch(false)
		} else {
			setInFetch(false)
			return
		}
	}

	const onClose = () => {
		setShowModal(false)
	}

	const onConfirm = async () => {
		if (!id) {
			return
		}

		setOnQueue(true)

		if (isEdit) {
			let payload = {
				community_id: id,
				name: title,
				content: content,
				command: command,
				id: currentPrompt.id,
			}
			await communityService.updatePrompt(payload)
		} else {
			let payload = {
				community_id: id,
				name: title,
				content: content,
				command: command,
			}
			await communityService.createCommunityPrompt(payload)
		}
		setShowModal(false)
		handleGlobalMessage('Prompt created success')
		setIsEdit(false)
		getAllPrompts()
	}

	const handleModalChange = () => {
		setShowModal(true)
	}

	const handleInputChange = (param: string, origin: string) => {
		if (origin === 'command') {
			setCommand(param)
		} else if (origin === 'title') {
			setTitle(param)
		} else if (origin === 'content') {
			setContent(param)
		}
	}

	const handleOpenEditModal = (prompt: Prompt) => {
		setCurrentPrompt(prompt)
		setIsEdit(true)
		setShowModal(true)
		setTitle(prompt.name)
		setCommand(prompt.command)
		setContent(prompt.content)
	}

	useEffect(() => {
		getAllPrompts()
	}, [])

	return (
		<div className="flex flex-col gap-2 py-4">
			{showModal && (
				<Modal
					title="Create new Prompt"
					open={showModal}
					onClose={onClose}
					onConfirm={onConfirm}
					confirmLabel={isEdit ? 'Save' : 'Create'}
					cancelLabel="Cancel">
					<div className="flex flex-col gap-1">
						<InputText
							type="text"
							value={title}
							label="Title"
							maxLength={30}
							required
							onChange={(e: any) => {
								handleInputChange(e, 'title')
							}}
						/>
						<InputText
							textHelper={`Only alphanumeric characters and hyphens are allowed; Activate this command by typing "/${command} " to chat input.`}
							type="text"
							value={command}
							maxLength={20}
							label="Command"
							required
							onChange={(e: any) => {
								handleInputChange(e, 'command')
							}}
						/>
						<div className="flex flex-col">
							<textarea
								onChange={e => {
									handleInputChange(e.target.value, 'content')
								}}
								value={content}
								maxLength={300}
								id="description"
								rows={4}
								className="block p-2.5 w-full text-sm text-gray-900 border-2  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
								placeholder="Write a summary in 50 words that sommanizes [topic or keyword]"></textarea>
							<TextStyleCaption className="text-gray-500 word-wrap wrap break-word w-[97%]">
								Format your variables using square brackets like this: [variable] . Make sure to enclose them with '['
								and ']' .
							</TextStyleCaption>
						</div>
					</div>
				</Modal>
			)}
			{inFetch ? (
				<Loading />
			) : prompts && prompts.length ? (
				prompts.map((prompt: Prompt, key: number) => (
					<div key={key} className="flex w-full flex-col  gap-1 p-2 border border-1 rounded-[4px] cursor-pointer">
						<div className="w-full flex items-center  justify-between">
							<p className="text-[18px]">{prompt.name}</p>
							{user && user.id == prompt.owner_id ? (
								<Button
									size="small"
									value="Edit"
									onClick={() => {
										handleOpenEditModal(prompt)
									}}></Button>
							) : (
								''
							)}
						</div>
						<div className="flex  w-full flex justify-between">
							<div className="flex flex-col">
								<TextStyleCaption>Command</TextStyleCaption>
								<p>{prompt.command}</p>
							</div>
							<div>
								<TextStyleCaption>Creator</TextStyleCaption>
								<Link to={`social/view/${prompt.owner_name}`}>
									<TextStyleBodyDefaultLarge>@{prompt.owner_name}</TextStyleBodyDefaultLarge>
								</Link>
							</div>
						</div>
					</div>
				))
			) : (
				''
			)}

			<div
				className="flex items-center gap-4 p-2 border border-1 rounded-[4px] hover:bg-gray-100 cursor-pointer"
				onClick={handleModalChange}>
				<span className="text-[30px] material-symbols-outlined">add_circle</span>
				<div className="flex flex-col">
					<TextStyleBodyDefaultMedium>Create new prompt</TextStyleBodyDefaultMedium>
					<TextStyleCaption>Add new custom prompt preset for a specific purpose</TextStyleCaption>
				</div>
			</div>
		</div>
	)
}
