import React from 'react'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import UserBalance from 'src/components/balances'
import KineContext from 'src/context/KineContext'

export const UserConfiguration: React.FC = () => {
	const { user } = React.useContext(KineContext)
	return (
		<div className="flex flex-col w-full">
			<div className="flex  gap-2 flex-col">
				<div className="flex items-center gap-4">
					<TextStyleBodyDefaultMedium>{user.name}</TextStyleBodyDefaultMedium>
					<TextStyleBodyDefaultMedium>
						<UserBalance />
					</TextStyleBodyDefaultMedium>
				</div>
				<div className="flex items-center gap-4">
					<TextStyleBodyDefaultMedium>
						Current Capacity Storage: <b>{parseFloat(user.max_storage)} / KB</b>
					</TextStyleBodyDefaultMedium>
				</div>
				<div className="flex items-center gap-4">
					<TextStyleBodyDefaultMedium>
						In use Storage: <b>{parseFloat(user.storage) || 0} / KB</b>
					</TextStyleBodyDefaultMedium>
				</div>
			</div>
		</div>
	)
}
