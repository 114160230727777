import React, { useContext, useEffect, useState } from 'react'
import TitleH1 from '../../components/_Dsc/typography/TitleH1'
import { getPost } from '../../service/social/socialService'
import Feed from '../social/components/feed/Feed'
import KineContext from '../../context/KineContext'

const PostDetails: React.FC = () => {
	const { profile } = useContext(KineContext)
	const [post, setPost] = useState([])
	const getPostById = async () => {
		let param = window.location.pathname.split('post/')
		let req = await getPost(param[1])
		if (req && req.data) setPost(req.data)
	}
	useEffect(() => {
		getPostById()
	}, [])
	return (
		<div className="w-full mb-10 ">
			<TitleH1 text={'Post'} />

			<div className="bg-white p-2 rounded-[4px]">
				<Feed posts={post} profile={profile} />
			</div>
		</div>
	)
}

export default PostDetails
