import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ReactPlayer from 'react-player'
import './styles.scss'
interface RichTextEditorProps {
	initialValue?: string
	readOnly?: boolean
}

const RichTextDisplay: React.FC<RichTextEditorProps> = ({ initialValue, readOnly }) => {
	const modules = {
		toolbar: readOnly
			? false
			: [
					[{ header: '1' }, { header: '2' }, { font: [] }],
					['bold', 'italic', 'underline', 'strike', 'blockquote'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					['link'],
					['clean'],
			  ],
	}

	const formats = [
		'header',
		'font',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'link',
		'color',
		'background',
	]

	// Função para renderizar o conteúdo com substituição de links do YouTube
	const renderContent = () => {
		if (!initialValue) return
		const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([a-zA-Z0-9_-]+)/gi
		const matches = initialValue.match(regex)
		if (matches) {
			return matches.map((url, index) => <ReactPlayer url={url} controls width="100%" key={index} />)
		}
		return null
	}

	return (
		<div className="h-full view-box cursor-pointer" style={{ whiteSpace: 'pre-line' }}>
			<ReactQuill
				value={initialValue}
				modules={modules}
				formats={formats}
				readOnly={readOnly}
				theme={readOnly ? 'bubble' : 'snow'}
				preserveWhitespace={true}
			/>
			{initialValue && initialValue.includes('/www.youtube.com/watch') && (
				<div className="show-preview-movie ">{renderContent()}</div>
			)}
		</div>
	)
}

export default RichTextDisplay
