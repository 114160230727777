import React from 'react'

import KineContext from '../../../../context/KineContext'

import { Loading } from '../../../../components/loading'

import { getProfile, getProfileTokens } from '../../../../service/profile/profileService'
import { translate } from '../../../../context/Translate'
import UserBalance from 'src/components/balances'
import { planksToTokens, tokensToPlanks } from 'src/service/substrate/formated'

export const UserProfile: React.FC = () => {
	const { user, color, apiState, currentLangues } = React.useContext(KineContext)

	const [userTokens, setUserTokens] = React.useState<any | undefined>(undefined)

	const getLoggedUserProfileStatus = async () => {
		if (user) {
			try {
				await getProfile(user, apiState)
			} catch (e) {
				console.log(e)
			}
		}
	}

	const getUserTokens = async () => {
		if (user) {
			try {
				let req: any = await getProfileTokens(user, apiState)
				setUserTokens(req)
			} catch (e) {
				console.log(e)
			}
		} else {
			return
		}
	}

	const verifyTokensUpdate = async () => {
		if (user) {
			try {
				let reqT: any = await getProfileTokens(user, apiState)
				setUserTokens(reqT)
			} catch (e) {
				console.log(e)
			}
		}
	}

	React.useEffect(() => {
		if (apiState) {
			getLoggedUserProfileStatus()
			getUserTokens()
			const interval = setInterval(() => {
				verifyTokensUpdate()
			}, 2000)

			return () => clearInterval(interval)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])
	return (
		<>
			{user && user.address ? (
				<div className="flex flex-col">
					<div className="flex items-center m-5">
						<div className="rounded-lg"></div>
						<div className="ml-9 flex flex-col ">
							<div className="flex items-center gap-10">
								<p className={`text-[${color.text}]`}>{translate('WALLET_STATUS', currentLangues.value)}</p>
								<h2 className={`text-[${color.text}]`}>
									{translate('NAME', currentLangues.value)}: {user.meta.name}
								</h2>
								<h2 className={`text-[${color.text}]`}>
									{translate('SOURCE', currentLangues.value)}: {user.meta.source}
								</h2>
								<h2 className={`text-[${color.text}] flex items-center gap-2`}>
									<span>Balance:</span> <UserBalance />
								</h2>
							</div>
						</div>
					</div>
					{userTokens && (
						<div className="flex items-center gap-5 mb-4 ml-14 wrap flex-wrap">
							<div className={` flex items-center gap-2 text-[${color.text}]`}>
								<p>{translate('REPUTATION', currentLangues.value)}:</p>
								<b>{userTokens?.reputation || 15}</b>
							</div>
							<div className={` flex items-center gap-2 text-[${color.text}]`}>
								<p>{translate('LOCK_FESTIVAL', currentLangues.value)}:</p>{' '}
								<b>{planksToTokens(userTokens?.lockedTokensFestival)}</b>
							</div>
							<div className={` flex items-center gap-2 text-[${color.text}]`}>
								<p>{translate('LOCK_RANKING', currentLangues.value)}:</p>{' '}
								<b>{planksToTokens(userTokens?.lockedTokensRanking)}</b>
							</div>
						</div>
					)}
				</div>
			) : (
				<Loading />
			)}
		</>
	)
}
