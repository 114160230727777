import React, { useState, useEffect } from 'react'
import { getFollowers } from '../../../service/social/socialService'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../../../components/loading'
import KineContext from '../../../context/KineContext'

interface Props {
	setOpen: any
	isTarget?: boolean
}

const Followers: React.FC<Props> = ({ setOpen, isTarget }) => {
	const { user } = React.useContext(KineContext)
	const [data, setData] = useState([])
	const [fetch, setFetch] = useState(true)
	const navigate = useNavigate()
	const getFollowersById = async () => {
		setFetch(false)
		let param = isTarget ? user.address : window.location.pathname.toString().split('/')[3]
		const req = await getFollowers(param)
		if (req && req.data) {
			setData(req.data)
		}
		setFetch(true)
	}

	const goToUserProfile = (val: any) => {
		navigate('/social/view/' + val.user_address)
		setOpen(false)
	}

	useEffect(() => {
		getFollowersById()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname.toString().split('/')[3]])
	return (
		<div className="w-full h-full">
			{fetch ? (
				<>
					{data && data.length ? (
						data?.map((val: any, i) => (
							<div key={i}>
								<div
									className="flex items-center gap-4 p-2 hover:bg-gray-200 cursor-pointer m-1"
									onClick={() => {
										goToUserProfile(val)
									}}>
									<img
										src={
											val.user_img
												? val.user_img
												: 'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM='
										}
										alt="user"
										style={{ width: '30px', height: '30px' }}
									/>
									<p>{val.user_name}</p>
								</div>
							</div>
						))
					) : (
						<p>No data to show</p>
					)}
				</>
			) : (
				<Loading />
			)}
		</div>
	)
}

export default Followers
