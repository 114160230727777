import React from 'react'
import KineContext from 'src/context/KineContext'
import { web3FromSource } from '@polkadot/extension-dapp'
import { stringToHex } from '@polkadot/util'
import { login } from 'src/service/profile/profileService'
import { Loading } from 'src/components/loading'
import InputText from 'src/components/_Dsc/InputText'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium, TextStyleBodyMedium } from 'src/components/_Dsc/typography'
import Button from 'src/components/_Dsc/Button'
import { useNavigate } from 'react-router-dom'
interface Props {
	accounts: any
	setIsLogin: (value: boolean) => void
	extensionSetup: () => void
	notShowDefaultValue?: boolean
	calback?: (param: any) => void
	selected?: any
	password: string
	setPassword: (value: string) => void
	currentAccount: any
	setCurrentAccount: any
	errorMessage: any
}

export const Accounts: React.FC<Props> = ({
	accounts,
	setIsLogin,
	extensionSetup,
	notShowDefaultValue,
	calback,
	selected,
	password,
	setPassword,
	currentAccount,
	setCurrentAccount,
	errorMessage,
}) => {
	const [showAcccounts, setShowAccounts] = React.useState(false)
	const navigate = useNavigate()
	const setAccount = (account: any) => {
		if (calback) {
			calback(account)
		}
		if (account) {
			setCurrentAccount(account)
		}
		setShowAccounts(false)
	}

	const changeShowAccount = () => {
		setShowAccounts(!showAcccounts)
	}

	const changePassword = (e: string) => {
		setPassword(e)
	}

	const handleChange = () => {
		navigate('/register-profile')
		setIsLogin(false)
	}

	React.useEffect(() => {
		extensionSetup()
		if (accounts && !notShowDefaultValue) {
			setCurrentAccount(accounts[0])
		}
		setPassword('')

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="w-full h-full relative">
			<TextStyleBodyDefaultMedium className="text-black">Accounts:</TextStyleBodyDefaultMedium>
			<div
				className="ant-dropdown-trigger flex items-center justify-between border-default  rounded-[4px] px-3 py-1"
				onClick={changeShowAccount}>
				<div className="flex items-center">
					<div className="p-1" style={{ gap: '8px' }}>
						<div className="ant-space-item">
							<div className="flex flex-col  font-bold">
								{currentAccount && currentAccount.meta ? (
									<TextStyleBodyDefaultMedium>
										{currentAccount && currentAccount.meta && currentAccount?.meta.name}
									</TextStyleBodyDefaultMedium>
								) : (
									<TextStyleBodyDefaultMedium>Select your wallet ... </TextStyleBodyDefaultMedium>
								)}
							</div>
						</div>
					</div>
				</div>
				<span className="mx-2 mb-1">
					<svg
						width="10"
						height="6"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={showAcccounts ? 'rotate-180' : ''}>
						<path
							d="M1.767.742 5 3.975 8.234.742a.83.83 0 1 1 1.175 1.175L5.584 5.742a.83.83 0 0 1-1.175 0L.584 1.917a.83.83 0 0 1 0-1.175.848.848 0 0 1 1.183 0Z"
							fill="#000"></path>
					</svg>
				</span>
			</div>
			{showAcccounts && (
				<div className="absolute w-full z-[999999999] h-[220px] bg-white mt-2  max-h-[300px] overflow-y-auto body-items rounded-[4px] border-default">
					{accounts &&
						accounts?.map(
							(
								value: {
									address: string
									meta: { name: string; source: string }
								},
								i: number,
							) => (
								<div
									className="flex items-center justify-between rounded-[4px] px-3 py-1 hover:opacity-80 cursor-pointer hover:bg-gray-200"
									key={i}
									onClick={() => {
										setAccount(value)
									}}>
									<div className="flex items-center">
										<div className="p-3" style={{ gap: '8px' }}>
											<div className="flex items-center gap-4">
												<svg
													fill="#c5bebe"
													height="32px"
													width="32px"
													version="1.1"
													id="Layer_1"
													viewBox="0 0 458.53 458.53"
													stroke="#c5bebe"
													strokeWidth="0.00458531">
													<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
													<g
														id="SVGRepo_tracerCarrier"
														strokeLinecap="round"
														strokeLinejoin="round"
														stroke="#CCCCCC"
														strokeWidth="18.341240000000003">
														<g id="XMLID_830_">
															<g>
																<g>
																	<path d="M336.688,343.962L336.688,343.962c-21.972-0.001-39.848-17.876-39.848-39.848v-66.176 c0-21.972,17.876-39.847,39.848-39.847h103.83c0.629,0,1.254,0.019,1.876,0.047v-65.922c0-16.969-13.756-30.725-30.725-30.725 H30.726C13.756,101.49,0,115.246,0,132.215v277.621c0,16.969,13.756,30.726,30.726,30.726h380.943 c16.969,0,30.725-13.756,30.725-30.726v-65.922c-0.622,0.029-1.247,0.048-1.876,0.048H336.688z"></path>
																	<path d="M440.518,219.925h-103.83c-9.948,0-18.013,8.065-18.013,18.013v66.176c0,9.948,8.065,18.013,18.013,18.013h103.83 c9.948,0,18.013-8.064,18.013-18.013v-66.176C458.531,227.989,450.466,219.925,440.518,219.925z M372.466,297.024 c-14.359,0-25.999-11.64-25.999-25.999s11.64-25.999,25.999-25.999c14.359,0,25.999,11.64,25.999,25.999 C398.465,285.384,386.825,297.024,372.466,297.024z"></path>
																	<path d="M358.169,45.209c-6.874-20.806-29.313-32.1-50.118-25.226L151.958,71.552h214.914L358.169,45.209z"></path>
																</g>
															</g>
														</g>
													</g>
													<g id="SVGRepo_iconCarrier">
														<g id="XMLID_830_">
															<g>
																<g>
																	<path d="M336.688,343.962L336.688,343.962c-21.972-0.001-39.848-17.876-39.848-39.848v-66.176 c0-21.972,17.876-39.847,39.848-39.847h103.83c0.629,0,1.254,0.019,1.876,0.047v-65.922c0-16.969-13.756-30.725-30.725-30.725 H30.726C13.756,101.49,0,115.246,0,132.215v277.621c0,16.969,13.756,30.726,30.726,30.726h380.943 c16.969,0,30.725-13.756,30.725-30.726v-65.922c-0.622,0.029-1.247,0.048-1.876,0.048H336.688z"></path>
																	<path d="M440.518,219.925h-103.83c-9.948,0-18.013,8.065-18.013,18.013v66.176c0,9.948,8.065,18.013,18.013,18.013h103.83 c9.948,0,18.013-8.064,18.013-18.013v-66.176C458.531,227.989,450.466,219.925,440.518,219.925z M372.466,297.024 c-14.359,0-25.999-11.64-25.999-25.999s11.64-25.999,25.999-25.999c14.359,0,25.999,11.64,25.999,25.999 C398.465,285.384,386.825,297.024,372.466,297.024z"></path>
																	<path d="M358.169,45.209c-6.874-20.806-29.313-32.1-50.118-25.226L151.958,71.552h214.914L358.169,45.209z"></path>
																</g>
															</g>
														</g>
													</g>
												</svg>
												<div className="flex flex-col font-semibold">
													<p className="">{value.meta.name}</p>
													<p className="text-lightBlue text-xs dark:text-blue-dark-medium false flex font-normal ">
														{value.address}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							),
						)}
				</div>
			)}
	
			{currentAccount && !notShowDefaultValue && (
				<div className="mt-2">
					<InputText
						label="Password:"
						value={password}
						type="password"
						onChange={setPassword}
						placeholder="password..."
					/>
					{errorMessage && (
						<TextStyleBodyDefault className="text-center mt-2 font-bold text-[16px] ">
							{errorMessage} <span>Please check the information provided and try again</span>
						</TextStyleBodyDefault>
					)}
				</div>
			)}
					<div className="flex items-center justify-center m-2">
										<Button onClick={handleChange} value="Register" type="subtle"></Button>
									</div>
		</div>
	)
}
