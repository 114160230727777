const isByProfile = [
	'Crypto Tube',
	'Best Crypto Advocates and Educators',
	'Best Crypto Podcasts',
	'Top Traders and Analysts',
	'Best Institutional Content',
	'Top Degen Tubers',
	'Best Podcasters',
	'Venture Capital and Institutional Content Creators',
	'Best AI Authors',
	'Best Comedy Channels',
]

const group1 = [
	'Crypto Tube',
	'Best Crypto Advocates and Educators',
	'Best Crypto Podcasts',
	'The best content About Web3 and Crypto',
	'Top Traders and Analysts',
	'5 Best Institutional Content',
	'Top Degen Tubers',
	'Venture Capital and Institutional Content Creators',
]
const group2 = [
	'Best AI Authors',
	'Social Media Content, Advertising and Marketing',
	'Deepfakes',
	'Experimental Experimental and Art Films',
	'AI Tools for Audio and Video Production',
	'Best Podcasts About A',
]
const group3 = [
	'Comedy Tube',
	'Best Stand-Up Comedian Videos',
	'Comedy Best Single Videos Online',
	'Best Video Memes',
	'Best AI-Generated Comedy',
	'Best Comic Kids and Animal Videos',
	'Best Videos on Politics and Politicians',
	'Best Comedy Channels',
]
const group4 = [
	'Cinema',
	'All Time Best Films',
	'All Time Best Asiatic Fiction',
	'The Best From South America',
	'Life-changing Documentary Films Online',
	'Best Shorts, All Genre, World Wide',
]
export { isByProfile, group1, group2, group3, group4 }
