import axios from 'axios'
import cheerio from 'cheerio'

export async function getChannelData(channelId: string): Promise<any> {
	try {
		const headers = {
			'x-cors-api-key': process.env.REACT_APP_KEY_PROXY,
		}
		const proxyUrl = 'https://cors.bridged.cc/'
		const response = await axios.get(proxyUrl + channelId, { headers })
		const html = response.data

		const $ = cheerio.load(html)
		const scripts = $('script')

		let ytInitialData

		scripts.each((index, element) => {
			const scriptContent = $(element).html()
			if (scriptContent && scriptContent.includes('var ytInitialData =')) {
				const startIndex = scriptContent.indexOf('var ytInitialData =') + 'var ytInitialData ='.length
				const endIndex = scriptContent.indexOf(';', startIndex)
				const jsonData = scriptContent.substring(startIndex, endIndex)
				try {
					let formated = JSON.parse(jsonData)
					ytInitialData = {
						name: formated.header.c4TabbedHeaderRenderer.title,
						desc: formated.metadata.channelMetadataRenderer.description,
						img: formated.header.c4TabbedHeaderRenderer.avatar.thumbnails[0].url,
						sub: formated.header.c4TabbedHeaderRenderer.subscriberCountText.simpleText,
					}
				} catch (e) {
					const name = $('meta[property="og:title"]').attr('content')
					const img = $('meta[property="og:image"]').attr('content')

					const desc = $('meta[property="og:description"]').attr('content')
					const sub = $('#yt-subscriber-count').text().trim()

					ytInitialData = {
						img,
						name,
						sub,
						desc,
					}
				}

				return false
			}
		})

		return ytInitialData
	} catch (error) {
		console.error('Erro ao buscar dados:', error)
		// Se ocorrer um erro, retorne ytInitialData mesmo que seja null
	}
}
