import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import DscIcons from 'src/components/_Dsc/icons'
import Modal from 'src/components/_Dsc/Modal'
import ImgCommunity from 'src/assets/kinera_communities.png'
import {
	TextStyleBodyDefault,
	TextStyleCaption,
	TextStyleH1,
	TextStyleH3,
	TextStyleLinkDefault,
} from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'

import { communityService } from 'src/service/community/communityService'
import { planksToTokens } from 'src/service/substrate/formated'
import UserBalance from 'src/components/balances'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Communities', link: '' },
]

export interface CommunityType {
	monthlyFee: string
}

export interface Community {
	communityType: { [key: string]: CommunityType }
	createdAt: string
	proposalReason: string
	socialUserName: string
	createdBy: string
	description: string
	id: string
	members: string
	name: string
	status: string
	voteResult: string
	votesApprove: string
	votesReject: string
	votingPeriodEnd: string
	votingPeriodStart: string
	icon: string
	category: string
}

const categories = [
	{
		label: 'Approve',
		value: 'Approve',
	},
	{
		label: 'Pending',
		value: 'Undecided',
	},
	{
		label: 'Reject',
		value: 'Reject',
	},
]

const Communities: React.FC = () => {
	const { user, setOnQueue, onQueue,  profile, handleGlobalMessage } = React.useContext(KineContext)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [inFetchUsers, setInFetchUsers] = useState<boolean>(false)
	const [userExistInCommunity, setuserExistInCommunity] = useState<boolean>(false)
	const [userPendingRequest, setUserPendingRequest] = useState<boolean>(false)
	const [communitites, setCommunities] = useState<Community[]>([])
	const [isSelected, setIsSelected] = useState('Approve')
	const [selectedCommunity, setSelectedComunity] = useState<Community>({} as Community)
	const [showModal, setShowModal] = useState<boolean>(false)

	const navigate = useNavigate()

	const getCommunitiesByType = async (type: string) => {
		setCommunities([])
		setInFetch(true)
		let req = await communityService.getCommunities(type)
		console.log(req)
		setCommunities(req)
		setInFetch(false)
	}

	const setFilterNotifications = (val: string) => {
		setCommunities([])
		setInFetch(true)
		setIsSelected(val)
		getCommunitiesByType(val)
	}

	const handleChangeModal = async (community: Community) => {
		setShowModal(true)
		setSelectedComunity(community)
		setInFetchUsers(true)
		let req = await communityService.verifyUserExistInCommunity(user, community.id)
		let pendingVerify = await communityService.verifyPendingRequest(user, community.id)
		setuserExistInCommunity(req)
		setUserPendingRequest(pendingVerify)
		setInFetchUsers(false)
	}

	const closeModal = () => {
		setShowModal(false)
	}

	const applyUserForCommunity = async (community: Community) => {
		setOnQueue(true)
		await communityService.applyForCommunity(user, community, handleGlobalMessage)
		closeModal()
		setOnQueue(false)
	}

	const applyUserForCommunityMonthly = async (community: Community) => {
		setOnQueue(true)
		await communityService.applyForCommunityMonthly(user, community)
		closeModal()
		setOnQueue(false)
	}

	React.useEffect(() => {
		getCommunitiesByType(isSelected)
	}, [])

	return (
		<div className="page-padding">
			{selectedCommunity &&
			selectedCommunity.communityType &&
			Object.keys(selectedCommunity.communityType)[0] &&
			showModal ? (
				<Modal
					open={showModal}
					title={'Apply for Membership'}
					onConfirm={() => {
						Object.keys(selectedCommunity.communityType)[0] === 'Private'
							? applyUserForCommunityMonthly(selectedCommunity)
							: applyUserForCommunity(selectedCommunity)
					}}
					confirmDisabled={inFetch ||
						Object.keys(selectedCommunity.communityType)[0] === 'Private' &&
						user.credits < Object.values(selectedCommunity.communityType)[0].monthlyFee
					}
					confirmLabel="Apply"
					onClose={closeModal}
					onCancel={closeModal}
					cancelLabel="Cancel">
					<div className="relative w-full h-full">
						{inFetchUsers ? (
							<Loading />
						) : (
							<div>
								{userExistInCommunity ? (
									<TextStyleBodyDefault>You are already in this community</TextStyleBodyDefault>
								) : userPendingRequest ? (
									<TextStyleBodyDefault>
										You have already made the request to join the community, wait for approval
									</TextStyleBodyDefault>
								) : (
									<div className="w-full flex flex-col gap-2">
										{user && user.address ? (
											<div className="flex flex-col">
												<TextStyleBodyDefault>{user.name}</TextStyleBodyDefault>
												<TextStyleBodyDefault className="flex items-center gap-2">
													Your wallet: <UserBalance />
												</TextStyleBodyDefault>
											</div>
										) : (
											''
										)}
										<div className="community-body-img"></div>
										<div className="z-[100] h-[200px] justify-center  flex flex-col gap2">
											<TextStyleH1>{selectedCommunity.name}</TextStyleH1>
											<TextStyleBodyDefault>
												Type: {Object.keys(selectedCommunity.communityType)[0]}
											</TextStyleBodyDefault>
											{Object.keys(selectedCommunity.communityType)[0] === 'Private' ? (
												<TextStyleCaption>
													This community requires monthly payment of{' '}
													<b>
														{planksToTokens(Object.values(selectedCommunity.communityType)[0].monthlyFee)} - KinexPlay
													</b>
													By clicking to enter, you agree to the discount of these amounts to pay for community
													registration
												</TextStyleCaption>
											) : (
												''
											)}

											{Object.keys(selectedCommunity.communityType)[0] === 'Private' &&
											user.credits < Object.values(selectedCommunity.communityType)[0].monthlyFee ? (
												<TextStyleLinkDefault className="text-red-500">Insuficient founds</TextStyleLinkDefault>
											) : (
												''
											)}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</Modal>
			) : (
				''
			)}

			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex items-center justify-between">
							<TextStyleH1>Communities</TextStyleH1>
							{user && user.address ? (
								<Button
									value="Create Community"
									size="small"
									onClick={() => {
										navigate('/community-proposal')
									}}
								/>
							) : (
								''
							)}
						</div>
						{/**
						 * <Tabs onChange={setFilterNotifications} options={categories} value={isSelected}></Tabs>
						 */}
					</div>
					{inFetch ? (
						<Loading />
					) : (
						<div className="mt-4 flex flex-col gap-2">
							{communitites && communitites.length ? (
								communitites.map((val: Community, index: number) => (
									<div
										key={index}
										className="bg-white p-4 flex flex-col w-full items-end justify-end gap-2 rounded-[4px]">
										<div className=" flex  w-full justify-between">
											<div className="flex items-center gap-2">
												<img src={val.icon} width="60px" height="60px" />
												<div className="flex flex-col gap-1">
													<TextStyleH3>{val.name}</TextStyleH3>
													<TextStyleBodyDefault>{val.description}</TextStyleBodyDefault>
												</div>
											</div>
											<div className="flex flex-col items-end gap-2">
												<div className="flex flex-col gap-1 items-end justify-end">
													<TextStyleCaption key={index}>{Object.keys(val.communityType)[0]}</TextStyleCaption>

													<div className="flex items-center gap-1">
														<span className="material-symbols-outlined">payments</span>
														{Object.keys(val.communityType)[0] === 'Private' ? (
															<TextStyleCaption>
																{planksToTokens(Object.values(val.communityType)[0].monthlyFee)} - KinexPlay
															</TextStyleCaption>
														) : (
															''
														)}
													</div>
												</div>
												<div className="flex items-center gap-1">
													<TextStyleBodyDefault>{val.members}</TextStyleBodyDefault>
													<DscIcons icon="group" />
												</div>
											</div>
										</div>
										{profile && isSelected === 'Approve' && user.address !== val.createdBy ? (
											<div>
												<div className="flex items-center gap-2">
													<Button
														value="Request entry"
														size="small"
														onClick={() => {
															handleChangeModal(val)
														}}
													/>
													<Button
														value="View community"
														size="small"
														onClick={() => {
															navigate(`/community/proposal/${val.id}`)
														}}
													/>
												</div>
											</div>
										) : (
											<Button
												value="View community"
												size="small"
												onClick={() => {
													navigate(`/community/proposal/${val.id}`)
												}}
											/>
										)}
									</div>
								))
							) : (
								<p>No data to display</p>
							)}
						</div>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default Communities
