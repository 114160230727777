import React from 'react'
import './styles.scss'
import Button from 'src/components/_Dsc/Button'
import { TextStyleCaption } from 'src/components/_Dsc/typography'
import IconButton from 'src/components/_Dsc/IconButton'
interface File {
	id: number
	url: string
	size: string
	document_name: string
	created_at: string
	owner_name: string
}

interface Props {
	file: File
	onSelect: (file: File) => void
}

export const DocumentCard: React.FC<Props> = ({ file, onSelect }) => {
	const handleChangeNavigate = (id: number) => {
		window.open(`https://shared-doc.kinera.network/document/${id}`, '_blank')
	}

	const formatDate = (dateString: string) => {
		const date = new Date(dateString)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
		const year = date.getFullYear()
		const time = date.toTimeString().split(' ')[0] // Get the time portion only (HH:MM:SS)

		return `${day}/${month}/${year} - ${time}`
	}
	return (
		<div className="relative bg-white rounded-md border border-gray-100 p-4 shadow-md shadow-black/5 w-full flex flex-col">
			<div className="children-absolute-bg"></div>
			<div className="flex justify-between pb-6 w-full overflow-hidden">
				<div className="flex w-full flex-col">
					<div className="flex items-center w-full mb-1">
						<div className="text-2xl font-semibold truncate max-w-[80%]">{file.document_name}</div>
					</div>
				</div>
			</div>
			<div className="text-sm flex justify-between text-gray-700">
				<div className="flex items-center gap-1">
					<span className="material-symbols-outlined text-[15px]">account_circle</span>
					<TextStyleCaption>
						<b>{file.owner_name || ''}</b>
					</TextStyleCaption>
				</div>
				{formatDate(file.created_at) || ''}
			</div>

			<div className="w-full flex justify-end gap-2 items-center">
				<Button
					size="small"
					onClick={() => {
						handleChangeNavigate(file.id ? file.id : file.id)
					}}
					value="View"
				/>
				{/**
                 *   <Button
					size="small"
                    type='warning'
					onClick={() => {
						onSelect(file)
					}}
					value="Publish"
				/>
                 */}
			</div>
		</div>
	)
}
