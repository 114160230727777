import * as React from 'react'

import Box from '@mui/material/Box'

import { Tab, Tabs } from '@mui/material'

import ExternalUploadMovie from './externalUpload'
import InternalUploadMovie from './internalUpload'
import DscModal from '../_Dsc/Modal'

export interface Props {
	isOpen: boolean
	close: any
	insertMovieInFestival: any
	fetchInser: any
	setExternalFetch: any
	fetchExternal: boolean
	isRanking: boolean
	setType?: any
	title: string
	type?: string
	byProfile?: boolean
	festivalData?: any
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 900,
	height: 'auto',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	overflowY: 'auto',
}

const tabStyle = {
	marginTop: '10px',
}

const AddMovie: React.FC<Props> = ({
	isOpen,
	close,
	insertMovieInFestival,
	fetchInser,
	setExternalFetch,
	fetchExternal,
	isRanking,
	setType,
	title,
	type,
	byProfile,
	festivalData,
}) => {
	const [uploadType, setUploadType] = React.useState<string>('external')

	const handleTabChange = (event: React.SyntheticEvent, newUploadType: string) => {
		setUploadType(newUploadType)
		setType(newUploadType)
	}

	return (
		<DscModal title="Add New data" open={isOpen} onCancel={close} onClose={close}>
			{/**	<Tabs value={uploadType} onChange={handleTabChange} centered style={tabStyle}>
					<Tab label="Add New data" value="external" />
				</Tabs> */}
			{uploadType === 'external' ? (
				<ExternalUploadMovie
					setExternalFetch={setExternalFetch}
					fetchExternal={fetchExternal}
					isRanking={isRanking}
					insertMovieInFestival={insertMovieInFestival}
					close={close}
					festivalData={festivalData}
					byProfile={byProfile ? byProfile : false}
				/>
			) : (
				<InternalUploadMovie
					data={[]}
					fetch={false}
					fetchInser={fetchInser}
					insertMovieInFestival={insertMovieInFestival}
				/>
			)}
		</DscModal>
	)
}

export default AddMovie
