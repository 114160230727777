import React, { useState, useEffect } from 'react'
import { globals } from '../../_helpers'

interface VideoPlayerProps {
	link: string
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ link }) => {
	const [videoHtml, setVideoHtml] = useState<string>('')

	useEffect(() => {
		globals.getVideoSrc(link, setVideoHtml)
	}, [link])

	return <div className="w-full main-frame-player" dangerouslySetInnerHTML={{ __html: videoHtml }} />
}

export default VideoPlayer
