import React, { useEffect, useRef, useState } from 'react';
import {
  DsyContainer,
  DsyHelper,
  DsyHelperText,
  DsyInputIcon,
  DsyInput,
  DsyLabel,
  DsyRequired,
  DsyWrapper,
  DsyHelperIcon,
  DsyInputClear,
  DsyInputLoading,
} from './styles';

import { IInputTextProps } from './interface';

const numberCharsToBeReplaced = /\D/g;

export const InputText: React.FC<IInputTextProps> = ({
  clear = false,
  debounce = 0,
  disabled = false,
  icon,
  iconIntensity = 'normal',
  iconPosition = 'left',
  invalid = false,
  label,
  loading = false,
  type = 'text',
  onChange,
  placeholder,
  readOnly = false,
  required = false,
  size = 'default',
  textHelper,
  valid = false,
  value,
  maxLength
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value || '');

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      // Verifica se o valor mudou antes de atualizar
      if (value !== currentValue) {
        setCurrentValue(value || '');
      }
    }
  }, [value]);

  const updateCurrentValue = (newValue: string) => {
    if (type === 'number' && newValue === '') {
      setCurrentValue('0');
    } else if (type === 'number') {
      setCurrentValue(newValue.replace(numberCharsToBeReplaced, ''));
    } else {
      setCurrentValue(newValue);
    }
    
    // Implementar debounce para onChange
    if (onChange) {
      if (debounce > 0) {
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
          onChange(newValue);
        }, debounce);
      } else {
        onChange(newValue);
      }
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCurrentValue(e.target.value);
  };

  const onClearHandler = () => {
    updateCurrentValue('');
  };

  return (
    <DsyWrapper disabled={disabled}>
      {label && (
        <DsyLabel>
          {label}
          {required && <DsyRequired>*</DsyRequired>}
        </DsyLabel>
      )}
      <DsyContainer size={size} disabled={disabled} invalid={invalid}>
        <DsyInput
          autoComplete="off"
          disabled={disabled}
          iconPosition={iconPosition}
          onChange={onChangeHandler}
          placeholder={placeholder}
          type={type === 'number' ? 'tel' : type}
          value={currentValue}
          readOnly={readOnly}
          maxLength={maxLength ? maxLength : 500}
        ></DsyInput>

        {clear && !loading && !disabled && !readOnly && currentValue && currentValue.length !== 0 && (
          <DsyInputClear
            iconPosition={iconPosition}
            iconIntensity={iconIntensity}
            clear={clear}
            className="material-icons"
            onClick={onClearHandler}
          >
            cancel
          </DsyInputClear>
        )}
        {loading && <DsyInputLoading iconPosition={iconPosition}></DsyInputLoading>}
        {icon && (
          <DsyInputIcon
            iconPosition={iconPosition}
            iconIntensity={iconIntensity}
            clear={clear}
            className="material-icons"
          >
            {icon}
          </DsyInputIcon>
        )}
      </DsyContainer>
      {textHelper && (
        <DsyHelper valid={valid} invalid={invalid}>
          {valid && <DsyHelperIcon className="material-icons">check_circle</DsyHelperIcon>}
          {invalid && <DsyHelperIcon className="material-icons">info</DsyHelperIcon>}
          <DsyHelperText>{textHelper}</DsyHelperText>
        </DsyHelper>
      )}
    </DsyWrapper>
  );
};

export default InputText;
