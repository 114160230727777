import React, { useState, useEffect, useContext } from 'react'
import './styles.scss'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import KineContext from 'src/context/KineContext'
import { Link } from 'react-router-dom'
import DscIcons from 'src/components/_Dsc/icons'
import ModalFollowersAndFollowing from 'src/pages/socialView/modal'
import { Tooltip } from '@mui/material'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'

interface Props {
	setIsLogin: (value: boolean) => void
}

const SocialSidebar: React.FC<Props> = ({ setIsLogin }) => {
	const [openSocial, setOpenSocial] = useState(false)
	const [currentTab, setCurrentTab] = useState('')
	const [imageError, setImageError] = useState(false)
	const { user, profile } = useContext(KineContext)

	let path = [
		{ label: 'Home', link: '/social' },
		{ label: 'Social', link: '' },
	]

	const handleChange = (tab: string) => {
		setOpenSocial(true)
		setCurrentTab(tab)
	}

	const verifyCurrentPath = () => {
		if (window.location.pathname.includes('view')) {
			path = [
				{ label: 'Home', link: '/social' },
				{ label: 'Social', link: '/social' },
				{ label: 'Profile', link: '' },
			]
		}
		if (window.location.pathname.includes('post')) {
			path = [
				{ label: 'Home', link: '/social' },
				{ label: 'Social', link: '/social' },
				{ label: 'Post', link: '' },
			]
		}

		return path
	}

	const renderBreadCrumb = () => {
		const req = verifyCurrentPath()
		return (
			<div className="bread-crumb">
				<Breadcrumb routes={req} />
			</div>
		)
	}

	useEffect(() => {
		verifyCurrentPath()
	}, [window.location.pathname])

	useEffect(() => {
		verifyCurrentPath()
	}, [])

	const handleImageError = () => {
		setImageError(true)
	}

	return (
		<div className="social-sidebar">
			<div className="mb-[10px]">{renderBreadCrumb()}</div>
			{user && (!profile || !profile.user) && (
				<div className="register">
					<div className="w-max bg-black p-1 rounded-[4px]" style={{ border: 'solid 1px #efcece' }}>
						<Link to="/register-profile">
							<span className="text-white text-sm cursor-pointer hover:opacity-80 whitespace-nowrap">
								Create social profile
							</span>
						</Link>
					</div>
				</div>
			)}
			{user && profile && profile.user && (
				<div className="user-data">
					<div className="flex items-center gap-4">
						<InitialSimbol
							profile={{
								img: profile.img,
								name: profile.name,
							}}
						/>
						<p className="user-data__text">{profile.name}</p>
					</div>
					<Tooltip title="Followers">
						<div
							className="flex items-center gap-4 cursor-pointer p-1 user-container-menu hover:bg-gray-100 rounded-[4px]"
							onClick={() => handleChange('followers')}>
							<DscIcons icon="favorite" />
							<p className="user-data__text">Followers</p>
						</div>
					</Tooltip>
					<Tooltip title="Following">
						<div
							className="flex items-center gap-4 cursor-pointer user-container-menu p-1 hover:bg-gray-300"
							onClick={() => handleChange('following')}>
							<DscIcons icon="favorite" color="pink" />
							<p className="user-data__text">Following</p>
						</div>
					</Tooltip>
				</div>
			)}
			{!user && !profile && (
				<div className="w-max bg-black p-1 rounded-[4px]" style={{ border: 'solid 1px #efcece' }}>
					<button onClick={() => setIsLogin(true)}>
						<span className="text-white text-sm cursor-pointer hover:opacity-80 whitespace-nowrap">
							Connect your account
						</span>
					</button>
				</div>
			)}
			{openSocial && (
				<div>
					<ModalFollowersAndFollowing
						isTarget={true}
						targetTab={currentTab}
						setOpen={setOpenSocial}
						open={openSocial}
					/>
				</div>
			)}
		</div>
	)
}

export default SocialSidebar
