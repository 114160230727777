export default {
	CONSTELATIONS: 'Constelaciones',
	RANKINGS: 'Clasificaciones',
	CURRENT: 'Actual',
	CREATE_PROFILE: 'Create Social profile',
	PUBLIC_PROFILE: 'Perfil público',
	PROFILE_SETTINGS: 'Configuración de perfil',
	SOCIAL_PAGE: 'Página social',
	FOLLOWERS: 'Seguidores',
	FOLLOWING: 'Siguiendo',
	MY_CONSTELLATIONS: 'Mis constellaciones',
	VOTED_RANKINGS: 'Clasificaciones votadas',
	VOTED_CONSTELLATIONS: 'Constelaciones votadas',
	REWARD_KINEX: 'Recompensa KinexPlay ',
	MODERATION: 'Moderación',
	HELP: 'Ayuda',
	CREATE_FESTIVAL_TEXT: 'Crea festivales y genera nuevas interacciones',
	FESTIVAL_DETAILS_TEXT: 'Crea, vota, vuelve a publicar y asegura nuevas interacciones.',
	CREATE_CONSTELLATION: 'Crear constelación',
	CREATE_SOCIAL_PROFILE_TO_CREATE_CONSTELLATION: 'Crea un perfil social para crear nuevas constelaciones.',
	WALLET_STATUS: 'Estado de la billetera',
	NAME: 'Nombre',
	SOURCE: 'Fuente',
	REPUTATION: 'Reputación',
	LOCK_FESTIVAL: 'Constelación de bloqueo de tokens',
	LOCK_RANKING: 'Fichas bloqueadas en clasificaciones',
}
