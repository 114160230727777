import { web3FromAddress } from '@polkadot/extension-dapp'
import { User } from '../../interface'
import { Keyring } from '@polkadot/api'
import { AccountId } from '@polkadot/types/interfaces'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import React from 'react'
import { tokensToPlanks } from '../substrate/formated'
import API from '../api'
import Cookies from "js-cookie"; // Adicione essa dependência

const getProfile = async (user: User, apiState: any) => {
	let arrObjectPosts: Array<object> = []
	var connect = await apiState

	const keyring = new Keyring({ type: 'sr25519' })
	const accountId = keyring.decodeAddress(user.address) as AccountId

	await connect.query.statTrackerModule.walletStats(accountId).then(async (res: any) => {
		arrObjectPosts.push(res.toHuman())
	})

	return arrObjectPosts
}

const getProfileTokens = async (user: User, apiState: any) => {
	try {
		var connect = await apiState

		const keyring = new Keyring({ type: 'sr25519' })
		const accountId = keyring.decodeAddress(user.address) as AccountId

		let req = await connect.query.statTrackerModule.walletTokens(accountId)
		return req.toHuman()
	} catch (err) {
		console.log(err)
		return null
	}
}

const getCurretBalance = async (user: User, apiState: any) => {
	try {
		var connect = await apiState

		const keyring = new Keyring({ type: 'sr25519' })
		const accountId = keyring.decodeAddress(user.address) as AccountId

		let req = await connect.query.system.account(accountId)
		return req.toHuman()
	} catch (err) {
		console.log(err)
		return null
	}
}

const createProfile = async (
	user: any,
	isNamePublic: boolean,
	isWalletPublic: boolean,
	userName: string,
	apiState: any,
) => {
	try {
		const MySwal = withReactContent(Swal)
		var connect = await apiState
		const injector = await web3FromAddress(user.address)

		var send = connect.tx.statTrackerModule.registerNewWallet(isNamePublic, isWalletPublic, userName)
		await send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {})

		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
			willClose: () => {
				window.location.reload()
			},
		})
	} catch (err) {
		console.log(err)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {},
		})
	}
}

const updateProfile = async (
	user: any,
	isNamePublic: boolean,
	isWalletPublic: boolean,
	userName: string,
	apiState: any,
) => {
	try {
		const MySwal = withReactContent(Swal)
		var connect = await apiState
		const injector = await web3FromAddress(user.address)
		const keyring = new Keyring({ type: 'sr25519' })
		const accountId = keyring.decodeAddress(user.address) as AccountId

		var send = connect.tx.statTrackerModule.updateWalletData(isNamePublic, isWalletPublic, userName, [accountId])
		await send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {})

		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
			willClose: () => {
				window.location.reload()
			},
		})
	} catch (err) {
		console.log(err)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {},
		})
	}
}

const rankingRewards = async (user: any, apiState: any) => {
	try {
		const MySwal = withReactContent(Swal)
		var connect = await apiState
		const injector = await web3FromAddress(user.address)

		var send = connect.tx.rankingListModule.claimRankingRewards()
		await send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {})

		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
			willClose: () => {
				window.location.reload()
			},
		})
	} catch (err) {
		console.log(err)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {},
		})
	}
}

const festivalReward = async (user: any, apiState: any) => {
	try {
		const MySwal = withReactContent(Swal)
		var connect = await apiState
		const injector = await web3FromAddress(user.address)

		var send = connect.tx.festivalModule.claimFestivalRewards()
		await send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {})

		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
			willClose: () => {
				window.location.reload()
			},
		})
	} catch (err) {
		console.log(err)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {},
		})
	}
}

const createUserData = async (formData: any) => {
	try {
		let req = await API.post(`/api/users/ipfs/user`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		return req
	} catch (err: any) {
		let req = { error: true }
		return req
	}
}

const updateUser = async (formData: any) => {
	try {
		let req = await API.post(`/api/users/update/user`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		return req
	} catch (err: any) {
		return err
	}
}

const getUserData = async (user: any) => {
	try {
		let req = await API.get(`/api/users/ipfs/` + user)
		return req.data
	} catch (err) {
		console.log(err)
	}
}

const getReward = async (user: any, last_block: string) => {
	const headers = {
		'x-cors-api-key': process.env.REACT_APP_KEY_PROXY,
	}

	try {
		let payload = {
			user_address: user.address,
			last_block: last_block,
		}
		let req = await API.post(`/api/reward/`, payload, {
			headers: headers,
		})
		return req.data
	} catch (err) {
		console.log(err)
	}
}

const getCurrentBlockReward = async (user: any) => {
	const headers = {
		'x-cors-api-key': process.env.REACT_APP_KEY_PROXY,
	}

	try {
		let req = await API.get(`/api/reward/` + user.address, {
			headers: headers,
		})
		return req.data
	} catch (err) {
		console.log(err)
	}
}

const dailyReward = async (user: any, apiState: any) => {
	try {
		const MySwal = withReactContent(Swal)
		var connect = await apiState
		const keyring = new Keyring({ type: 'sr25519' })
		const alice = keyring.addFromUri('//Alice')

		let value = tokensToPlanks(2500)
		var send = connect.tx.balances.transferAllowDeath(user.address, value)
		await send.signAndSend(alice)

		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
			willClose: () => {
				window.location.reload()
			},
		})
	} catch (err) {
		console.log(err)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {},
		})
	}
}

const login = async (user: any, password: string) => {
	const headers = {
		'x-cors-api-key': process.env.REACT_APP_KEY_PROXY,
	}

	try {
		let payload = {
			user: user,
			password: password,
		}
		let req = await API.post(`/api/login`, payload, {
			headers: headers,
		})
    if (req && req.data) {
			// Armazena os dados do usuário no cookie kinera-cookie
			Cookies.set('kinera-cookie', JSON.stringify(req.data), {
				sameSite: 'None',
				secure: true,
			})
		}
		return req
	} catch (err: any) {
		if (err && err.response && err.response) {
			return err.response
		} else {
			return err
		}
	}
}

const logout = async () => {
	const headers = {
		'x-cors-api-key': process.env.REACT_APP_KEY_PROXY,
	}

	try {
		let req = await API.post(
			`/api/logout`,
			{},
			{
				headers: headers,
			},
		)
		let userData = Cookies.get('kinera-cookie')
		if (userData) {
			Cookies.remove('kinera-cookie')
		}
		return req.data
	} catch (err: any) {
		if (err && err.response && err.response.data) {
			return err.response.data
		} else {
			return err
		}
	}
}

const fetchTransactionHistory = async (userAddress: string, apiState: any) => {
    const transactions: Array<object> = [];

    // Verifique se `userAddress` é uma string válida base58
	console.log('userAddress', userAddress)
    if (typeof userAddress !== 'string' || !userAddress) {
        throw new Error("Endereço do usuário inválido.");
    }

    // Configurando a chave e decodificando o endereço do usuário
    const keyring = new Keyring({ type: 'sr25519' });
    const accountId = keyring.decodeAddress(userAddress) as AccountId;

    let api = await apiState;

    // Consultando os eventos de blocos recentes
    const unsubscribe = await api.rpc.chain.subscribeFinalizedHeads(async (header: any) => {
        const blockHash = await api.rpc.chain.getBlockHash(header.number);
        const block = await api.rpc.chain.getBlock(blockHash);
        const allRecords = await api.query.system.events.at(blockHash);

        block.block.extrinsics.forEach((extrinsic: any, index: number) => {
            const { method: { method, section } } = extrinsic;

            // Filtrando as transações que envolvem o endereço do usuário
			console.log("extrinsic.signer?.toString() ", extrinsic.signer?.toString() )
            if (extrinsic.signer?.toString() === userAddress) {
                transactions.push({
                    blockNumber: header.number.toNumber(),
                    extrinsicIndex: index,
                    method: `${section}.${method}`,
                    signer: extrinsic.signer.toString(),
                    args: extrinsic.args.map((arg: any) => arg.toString()),
                    success: allRecords[index].phase.isApplyExtrinsic,
                });
            }
        });
    });
console.log('unsubscribe', unsubscribe)
    return transactions;
}


export {
	getProfile,
	createProfile,
	updateUser,
	updateProfile,
	getProfileTokens,
	getReward,
	rankingRewards,
	getCurretBalance,
	createUserData,
	getUserData,
	festivalReward,
	dailyReward,
	getCurrentBlockReward,
	login,
	logout,
	fetchTransactionHistory
}
