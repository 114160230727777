import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { UploadFile } from './Actions/components/UploadFile'
import { communityService } from 'src/service/community/communityService'
import { CommunityFile } from './Actions/components/GPT/components/Rags'
import KineContext from 'src/context/KineContext'

import { removeUserFile } from 'src/service/storage/Ipfs'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'

interface Props {
	community_id: string
}

export const CommunityFiles: React.FC<Props> = ({ community_id }) => {
	const { user, setOnQueue, handleGlobalMessage } = React.useContext(KineContext)
	const [showModalUpload, setShowModalUpload] = useState<boolean>(false)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [files, setFiles] = useState<CommunityFile[] | null>(null)
	const getAllFilesCommunity = async () => {
		setInFetch(true)
		if (community_id) {
			let req = await communityService.getCommunitiesRags(community_id)
			if (req) {
				setFiles(req)
			}
		}
		setInFetch(false)
	}

	const removeFile = async (file: CommunityFile) => {
		setOnQueue(true)
		handleGlobalMessage('Your order is being processed and the file will soon be deleted.')
		await removeUserFile(user.address, file.file_hash)
		getAllFilesCommunity()
	}

	const redirectToDocument = (file: CommunityFile) => {
		window.open(file.url, '_blank')
	}

	useEffect(() => {
		getAllFilesCommunity()
	}, [])
	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			{showModalUpload ? <UploadFile setShowModalUpload={setShowModalUpload} showModalUpload={showModalUpload} /> : ''}
			<div className="flex items-center gap-2 w-full justify-end">
				<Button
				icon='add'
					value="Upload file"
					size="small"
					onClick={() => {
						setShowModalUpload(true)
					}}
				/>
			</div>

			{inFetch ? (
				<Loading />
			) : (
				<div className="w-full h-full">
					{files && files.length ? (
						files.map((file: CommunityFile, key: number) => (
							<div
								key={key}
								className="flex w-full gap-1 p-4 justify-between border border-1 rounded-[4px] cursor-pointer">
								<div className="flex items-center gap-2">
									<span className="material-symbols-outlined">picture_as_pdf</span>
									{file.file_name}
								</div>

								<div className="flex items-center gap-2">
									{file.user_address === user.address ? (
										<Button value="Remove" icon='delete' type="critical" size="small" onClick={() => removeFile(file)} />
									) : (
										''
									)}
									<Button value="View" icon='visibility' size="small" onClick={() => redirectToDocument(file)} />
								</div>
							</div>
						))
					) : (
						<TextStyleBodyDefaultMedium>There are no files in this community yet</TextStyleBodyDefaultMedium>
					)}
				</div>
			)}
		</div>
	)
}
