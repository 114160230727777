import React, { useState } from 'react'
import { TextStyleBodyDefault } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { getTransactions } from 'src/service/founds'
import { fetchTransactionHistory } from 'src/service/profile/profileService'

interface Transaction {
	user_address: string
	type: string
	value: string
	created_at: string
}

export const TransactionHistory: React.FC = () => {
	const { user, apiState } = React.useContext(KineContext)
	const [transactions, setTransactions] = useState([])
	const [inFetch, setInFetch] = useState(true)
	const getAllTransactions = async () => {
		setInFetch(true)
		let req = await getTransactions(user.address)
		if (req && req.data) {
			setTransactions(req.data)
		}
		setInFetch(false)
	}

	const formatDate = (dateString: string) => {
		const date = new Date(dateString)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
		const year = date.getFullYear()
		const time = date.toTimeString().split(' ')[0] // Get the time portion only (HH:MM:SS)

		return `${day}/${month}/${year} - ${time}`
	}

	const getTransactionsData = async () => {
		console.log('entrei')
		let req = await fetchTransactionHistory(user.address, apiState)
		console.log('req0', req)
	}

	React.useEffect(() => {
		getAllTransactions()
		if(apiState && user) {
			getTransactionsData()
		}
	}, [user, apiState])
	
	return (
		<div className="w-full h-full">
			{!inFetch ? (
				<div className='flex flex-col gap-2'>
					{transactions && transactions.length ? (
						transactions.map((transaction: Transaction, key: number) => (
							<div className="flex flex-col p-2 rounded-[4px] border border-[#eded]">
								<TextStyleBodyDefault>Buy {transaction.type}</TextStyleBodyDefault>
								<div className="flex items-center justify-between">
									<TextStyleBodyDefault>
										Value {transaction.type === 'storage' ? `${transaction.value} MB` : transaction.value}
									</TextStyleBodyDefault>
									<TextStyleBodyDefault>{formatDate(transaction.created_at)}</TextStyleBodyDefault>
								</div>
							</div>
						))
					) : (
						<TextStyleBodyDefault>You have no transactions yet</TextStyleBodyDefault>
					)}
				</div>
			) : (
				<Loading />
			)}
		</div>
	)
}
