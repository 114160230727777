import styled from 'styled-components'

import { cssTextStyleBodyDefault, cssTextStyleBodyDefaultMedium, cssTextStyleCaption } from '../typography'
import { InputTextIconIntensity, InputTextIconPosition, InputTextSizeType } from './interface'
import { cssSpinningLoader } from '../Classes'

interface IDsyWrapper {
	disabled: boolean
}

const wrapperDisabled = `
	pointer-events: none;
	cursor: not-allowed;
`

export const DsyWrapper = styled.div<IDsyWrapper>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;

	${({ disabled }) => disabled && wrapperDisabled}
`

export const DsyLabel = styled.div`
	${cssTextStyleBodyDefaultMedium}
`

export const DsyRequired = styled.span`
	color: var(--action-critical);
`

interface IDsyContainer {
	size: InputTextSizeType
	disabled: boolean
	invalid: boolean
}

export const DsyContainer = styled.span<IDsyContainer>`
	position: relative;
	display: flex;
	gap: 8px;
	align-items: center;
	background-color: var(--text-on);
	border-radius: var(--border-radius);
	border: 2px solid var(--border-default);
	width: 100%;
	height: 100%;
	padding: ${({ size }) => (size === 'small' ? '3px 12px' : '7px 12px')};

	&:hover {
		background-color: var(--surface-neutral);
		border: 2px solid var(--border-disable);
	}

	&:focus-within {
		background-color: var(--surface-default);
		border: 2px solid var(--border-primary);
	}

	${({ disabled }) => disabled && 'background-color: var(--surface-off);'}

	${({ invalid }) => invalid && 'border: 2px solid var(--border-critical) !important;'}
`

interface IDsyInput {
	iconPosition: InputTextIconPosition
}

export const DsyInput = styled.input<IDsyInput>`
	${cssTextStyleBodyDefault}
	width: 100%;
	border: none !important;
	line-height: 20px !important;
	color: var(--text-default) !important;
	background: transparent !important;
	transition: none !important;

	&:disabled {
		color: var(--text-sub) !important;
		&::placeholder {
			color: var(--text-sub);
		}
	}
	order: ${({ iconPosition }) => (iconPosition === 'right' ? 1 : 2)};
`

interface IDsyInputIcon {
	iconIntensity: InputTextIconIntensity
	iconPosition: InputTextIconPosition
	clear: boolean
}

export const DsyInputClear = styled.i<IDsyInputIcon>`
	font-size: 14px;
	color: var(--icon-neutral);
	left: auto !important;
	right: 12px;
	pointer-events: all;
	cursor: pointer;
	order: ${({ iconPosition }) => (iconPosition === 'right' ? 2 : 3)};
`

export const DsyInputIcon = styled.i<IDsyInputIcon>`
	font-size: 20px;
	order: ${({ iconPosition }) => (iconPosition === 'right' ? 3 : 1)};
	color: ${({ iconIntensity }) => (iconIntensity === 'strong' ? 'var(--icon-default)' : 'var(--icon-neutral)')};
`

interface IDsyHelper {
	valid: boolean
	invalid: boolean
}

export const DsyHelper = styled.div<IDsyHelper>`
	${cssTextStyleCaption}
	line-height: 16px !important;
	color: var(--text-sub);
	padding-top: 4px;
	display: flex;
	align-items: center;
	gap: 4px;
	${({ valid }) => valid && 'color: var(--success);'}
	${({ invalid }) => invalid && 'color: var(--text-critical);'}
`

export const DsyHelperText = styled.div``

export const DsyHelperIcon = styled.i`
	font-size: 16px;
`

export const DsyInputLoading = styled.i<IDsyInput>`
	right: 12px;
	${cssSpinningLoader}
	order: ${({ iconPosition }) => (iconPosition === 'right' ? 2 : 3)};
`
